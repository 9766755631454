<template>
  <main>
    <h3 id="title" :class="{feedTitle: isMini}">{{ dictionary.topTen.proTitle }}</h3>
    <!--    <tabs id="categoryTabs" :tabs="tabs" :active-tab="tab"/>-->

    <div id="topBar">
      <div id="filterPanel">
        <DropDown :options="tabs" :default-selected="'market'" @select="tab = $event.value; loadTop()"/>
        <tabs id="filterTabs" @tabClick="setTab" :no-border="true" :round="true" :tabs="categoryTabs"
              :active-tab="categoryTabActive"/>
      </div>
      <ButtonsGroup :active="activeButton" :buttons="marketStatusButtons" @selected="activeButton = $event.value; loadTop()"/>
      <big-note :small="true"
                :note="hideNoteOnFilters.includes(tab) ? dictionary.messages.topVolume : dictionary.messages.topMktCap"/>
    </div>

    <big-loader v-if="isLoading"></big-loader>
    <section v-else ref="gainers">

      <DataGridTable :is-compact="true"
                     :key="tableKey"
                     :data="list"
                     :loaded="isLoading"
                     :default-sort="defaultSort"
                     :heightElements="['topBar','title']"
                     :default-sort-direction="defaultSortDirection"
                     :fixed-col="0">
        <template #thead="{thClick, sortKey, sortDirection, xAxisScrolled}">
          <HeadCell v-for="(th, thIndex) in headers"
                    :sortKey="sortKey"
                    :sortDirection="sortDirection"
                    :sortValue="th.sortValue"
                    @sort="thClick(th.sortValue)">
            {{ th.label }}
          </HeadCell>
        </template>

        <template #tbody="{rows}">
          <tr v-for="(row, rowIndex) in rows" :key="`r-${rowIndex}`">
            <td>
              <div class="hSpaceBetween">
                <div class="symbolTd">
                  <symbol-link :symbol="row.symbol"/>
                  <!--                  <router-link :to="`/symbol/${row.symbol}`"/>-->
                  <symbol-logo :small="true" :symbol="row.symbol"/>
                  {{ row.symbol }}
                </div>

                <div :dir="uiDirection" class="addRemoveSymbol add fixedSixty"
                     @click="addRemoveWatchlistSymbol(row.symbol)"
                     v-if="!watchlist.watchlist.includes(row.symbol)">
                  <span>{{ dictionary.buttons.addToPortfolioShort }}</span>
                  <i class="ri-folder-add-line"/>
                </div>
                <div :dir="uiDirection" class="addRemoveSymbol remove fixedSixty"
                     @click="addRemoveWatchlistSymbol(row.symbol)"
                     v-if="watchlist.watchlist.includes(row.symbol)">
                  <span>{{ dictionary.buttons.removeFromPortfolioShort }}</span>
                  <i class="ri-folder-reduce-line"/>
                </div>
              </div>

            </td>
            <td>{{ row.name }}</td>
            <td>
              <symbol-label :key="`s-${row.symbol}`" :pre-market-quote="row.tradingData.preMarket" :post-market-quote="row.tradingData.postMarket" :onlyPostMarket="activeButton === 'postMarket'" :onlyPreMarket="activeButton === 'preMarket'" :symbol="row.tradingData" :price-only="true" :always-on="true"/>
            </td>
            <td>
              <span style="width: 60px; display: inline-block">{{ volumeValue(row) }}</span> <span
                v-if="row.tradingData.isAboveAvgVolume && activeButton === 'tradingHours'" class="unusualVolume">{{
                dictionary.symbolPage.unusualVolume
              }}</span>
            </td>
            <td>
              {{ row.tradingData.formattedMarketCap }}
            </td>
            <td class="bidi">{{ row.eods?.['5D'] || '-' }}</td>
            <td class="bidi">{{ row.eods?.['1M'] || '-' }}</td>
            <td class="bidi">{{ row.eods?.['6M'] || '-' }}</td>
            <td class="bidi">
              {{ row.tradingData.formattedPe }}
            </td>
            <td class="bidi">
              {{ row.tradingData.formattedEps }}
            </td>
            <td>{{ row.sector }}</td>
            <td>{{ row.industry }}</td>
          </tr>
        </template>
      </DataGridTable>

    </section>
  </main>
</template>

<script>

import TopTen from '../services/topTen';
import SymbolLabel from "../components/symbols/symbol";
import BigLoader from "../components/ui/BigLoader";
import DataGridTable from "../components/dataGrid/DataGridTable";
import Tabs from "../components/ui/Tabs";
import HeadCell from "../components/dataGrid/HeadCell";
import SymbolLogo from "../components/symbols/symbolLogo";
import UserService from "../services/user";
import {useWatchlist} from "../stores/watchlist";
import DropDown from "../components/ui/DropDown";
import SymbolLink from "../components/symbols/symbolLink";
import BigNote from "../components/ui/BigNote";

import quoteStreaming from "../composables/quoteStreaming";
import ButtonsGroup from "../components/ui/ButtonsGroup";

export default {
  name: "TopTenPro",
  components: {
    ButtonsGroup,
    BigNote, SymbolLink, DropDown, SymbolLogo, HeadCell, Tabs, DataGridTable, BigLoader, SymbolLabel},
  props: ['prices', 'isMini'],
  setup() {

    const {updateStream, leaveStream} = quoteStreaming();
    return {updateStream, leaveStream};
  },
  data() {
    return {
      gainers: [],
      losers: [],
      active: [],
      watchlist: useWatchlist(),
      tab: 'market',
      defaultSort: 'changeP',
      defaultSortDirection: -1,
      categoryTabActive: 'gainers',
      hideNoteOnFilters: ['nasdaq100',
        'sp500',
        'microCap',
        'smallCap',
        'midCap',
        'largeCap',
        'megaCap'],
      tableKey: 0,
      activeButton: 'tradingHours',
      marketStatusButtons: [{
        value: 'preMarket',
        label: 'Pre-market'
      },{
        value: 'tradingHours',
        label: 'Trading hours'
      }, {
        value: 'postMarket',
        label: 'Post-market'
      }],
      headers: [{
        label: this.dictionary.symbolPage.symbol,
        sortValue: 'symbol'
      },
        {
          label: this.dictionary.symbolPage.name,
        },{
          label: this.dictionary.symbolPage.price,
          sortValue: this.priceSortValue,
        }, {
          label: this.dictionary.symbolPage.volume,
          sortValue:  this.volumeSortValue
        },
        {
          label: this.dictionary.symbolPage.marketCap,
          sortValue: 'tradingData.marketCap'
        },
        {
          label: this.dictionary.trendingStocks.priceChange + ' ' + this.dictionary.chart.periods['5d'],
          sortValue: 'eods.5D'
        },
        {
          label: this.dictionary.trendingStocks.priceChange + ' ' + this.dictionary.chart.periods['1m'],
          sortValue: 'eods.1M'
        },
        {
          label: this.dictionary.trendingStocks.priceChange + ' ' + this.dictionary.chart.periods['6m'],
          sortValue: 'eods.6M'
        },
        {
          label: this.dictionary.symbolPage.pe,
          sortValue: 'tradingData.pe'
        },
        {
          label: this.dictionary.symbolPage.eps,
          sortValue: 'tradingData.eps'
        },
        {
          label: this.dictionary.symbolPage.sector,
          sortKey: 'sector'
        }, {
          label: this.dictionary.symbolPage.industry,
          sortKey: 'industry'
        }],
      categoryTabs: [{
        value: 'gainers',
        label: this.dictionary.topTen.gainers
      }, {
        value: 'losers',
        label: this.dictionary.topTen.losers
      }, {
        value: 'active',
        label: this.dictionary.topTen.active
      }],
      topTen: null,
      isLoading: true,
      tabs: [{
        value: 'market',
        label: this.dictionary.topTen.market
      }, {
        value: 'nasdaq100',
        label: 'NASDAQ 100'
      }, {
        value: 'sp500',
        label: 'S&P500'
      },
        {value: 'microCap', label: this.dictionary.topTen.microCap},
        {value: 'smallCap', label: this.dictionary.topTen.smallCap},
        {value: 'midCap', label: this.dictionary.topTen.midCap},
        {value: 'largeCap', label: this.dictionary.topTen.largeCap},
        {value: 'megaCap', label: this.dictionary.topTen.megaCap},
        {value: 'Communication Services', label: this.dictionary.topTen.sectors['Communication Services']},
        {value: 'Consumer Cyclical', label: this.dictionary.topTen.sectors['Consumer Cyclical']},
        {value: 'Energy', label: this.dictionary.topTen.sectors['Energy']},
        {value: 'Financial Services', label: this.dictionary.topTen.sectors['Financial Services']},
        {value: 'Real Estate', label: this.dictionary.topTen.sectors['Real Estate']},
        {value: 'Technology', label: this.dictionary.topTen.sectors['Technology']},
        {value: 'Basic Materials', label: this.dictionary.topTen.sectors['Basic Materials']},
        {value: 'Industrials', label: this.dictionary.topTen.sectors['Industrials']},
        {value: 'Utilities', label: this.dictionary.topTen.sectors['Utilities']},
        {value: 'Consumer Defensive', label: this.dictionary.topTen.sectors['Consumer Defensive']},
        {value: 'Healthcare', label: this.dictionary.topTen.sectors['Healthcare']}],
      loadingInterval: null
    }
  },
  computed: {
    list() {
      if (!this.topTen) return [];
      if (this.categoryTabActive === 'gainers') {
        this.defaultSort = 'changeP';
        this.defaultSortDirection = -1;
        return this.gainers;
      }
      if (this.categoryTabActive === 'losers') {
        this.defaultSort = 'changeP';
        this.defaultSortDirection = 1;
        return this.losers;
      }
      if (this.categoryTabActive === 'active') {
        this.defaultSort = 'volume';
        this.defaultSortDirection = -1;
        return this.active;
      }
    }
  },
  methods: {
    priceSortValue () {
      return this.activeButton === 'tradingHours' ? 'changeP' : this.activeButton === 'preMarket' ? 'tradingData.preMarket.changeP' : 'tradingData.postMarket.changeP';
    },
    volumeSortValue () {
      return this.activeButton === 'tradingHours' ? 'volume' : this.activeButton === 'preMarket' ? 'tradingData.preMarket.volume' : 'tradingData.postMarket.volume';
    },
    volumeValue (row) {

      if (this.activeButton === 'tradingHours') return  row.tradingData.formattedVolume;
      if (this.activeButton === 'preMarket') return  row.tradingData.preMarket.formattedVolume;
      if (this.activeButton === 'postMarket') return  row.tradingData.postMarket.formattedVolume;
    },
    setTab(tab) {

      this.categoryTabActive = tab;

      if (tab === 'gainers') {
        this.defaultSort = 'changeP';
        this.defaultSortDirection = -1;
      }
      if (tab === 'losers') {
        this.defaultSort = 'changeP';
        this.defaultSortDirection = 1;
      }
      if (tab === 'active') {
        this.defaultSort = 'volume';
        this.defaultSortDirection = -1;
      }

      this.tableKey++;
    },
    async addRemoveWatchlistSymbol(symbol) {

      if (this.watchlist.watchlist.includes(symbol)) {

        try {
          mixpanel.track('symbol:remove', {client: window.location.host, symbol: symbol});
        } catch (e) {
        }

        const watchlist = await UserService.removeSymbolFromWatchlist(symbol)
        this.watchlist.updateWatchlist(watchlist);
      } else {

        try {
          mixpanel.track('symbol:add', {client: window.location.host, symbol: symbol});
        } catch (e) {
        }
        const watchlist = await UserService.addSymbolToWatchlist(symbol)
        this.watchlist.updateWatchlist(watchlist);
      }
    },
    async loadTop(showLoading = true, shouldLeave = true) {

      if (showLoading) this.isLoading = true;

      this.leaveStream('topten');
      this.topTen = await TopTen.getTopTenPro(this.tab, this.activeButton);

      this.gainers = this.topTen.gainers;
      this.losers = this.topTen.losers;
      this.active = this.topTen.active;

      let symbols = [
        ...this.gainers.map(item => item.symbol),
        ...this.losers.map(item => item.symbol),
        ...this.active.map(item => item.symbol),
      ];

      this.updateStream(symbols, 'topten');
      // this.$emit('topLoaded', Array.from(symbols));
      if (showLoading) this.isLoading = false;
    }
  },
  beforeUnmount() {
    this.leaveStream('topten');
    clearInterval(this.loadingInterval);
  },
  beforeMount() {
    this.loadTop().then().catch();
    this.loadingInterval = setInterval(() => {

      this.loadTop(false).then().catch()
    }, 1000 * 60);
  }
}
</script>

<style scoped lang="scss">

@import "../assets/style/vars";
@import "../assets/style/calendar";
@import "../assets/style/general";

.tab {
  width: 33%;

}

.symbolRow {
  border-bottom: 1px solid var(--notficationBorderColor);
  padding: $smallGap;

  &:last-of-type {
    border-bottom: none;
  }
}

h2 {
  margin: 0 $smallGap $smallGap;
  padding: 0;
}

h3 {
  color: var(--textColor);
  margin: 0;
  padding: 5px 10px;

}

#topTable {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  section {
    width: 100%;
  }
}

.symbolTd {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;

  a {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.addRemoveSymbol {
  flex: 0 0 55px !important;
  width: 55px;
  margin-left: auto;

  &[dir="rtl"] {
    margin-left: unset;
    margin-right: auto;
  }

  i {
    display: none;
  }

  @media(max-width: 660px) {

    flex: 0 0 30px !important;
    width: 30px;
    height: 20px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    i {
      display: block;
    }

    span {
      display: none;
    }
  }
}

.tabs.round {
  z-index: 2;
  padding: 3px 5px !important;
  margin: 0 !important;
}

#filterPanel {
  padding: 0 10px;
  display: flex;
  align-items: center;
  gap: 1rem;
}

#topBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--notficationBorderColor) !important;

  @media(max-width: 660px) {
    display: block;
  }
}

.tabs:not(.round) {
  position: unset;
}

</style>
