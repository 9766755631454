<template>
  <div>

    <div class="row calendarHeadersRow">
      <div class="cell center"> {{ dictionary.calendars.headers.sharesOffered }}</div>
      <div class="cell center"> {{ dictionary.calendars.headers.ipoPrice }}</div>
      <div class="cell center"> {{ dictionary.calendars.headers.sharesValue }}</div>
    </div>

    <section v-for="(day, dayIndex) in calendar">
      <div class="calendarDateRow">
        {{ day.format }}
      </div>
      <div v-for="(event, eventIdx) in day.events" :key="event._id" class="row">

        <div class="cell company">
          <div>
          {{event.company}} ({{event.symbol}})
          </div>
          <div>{{event.exchange || ''}}</div>
        </div>

        <div class="cell center">{{event.sharesOffered || '-'}}</div>
        <div class="cell center">{{event.price || '-'}}</div>
        <div class="cell center">{{event.sharesValue || '-'}}</div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "IPOcalendar",
  props: ['calendar'],
  data() {
    return {}
  },
  computed: {

  },
  methods: {}
}
</script>

<style scoped lang="scss">

@import "../../assets/style/calendar";

.preMarket {
  //color: var(--highlightColor);
}

.afterMarket {
  color: var(--afterMarketColor);
}

.cell {
  width: 25%;
}

.row {
  flex-wrap: wrap;
  justify-content: space-between;
}

.company {
  box-sizing: border-box;
  font-weight: 600;
  background: #fafafa;
  unicode-bidi: plaintext;
  flex: 1 0 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
