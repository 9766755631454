<template>
  <div>
    <h1>Your Marketbit.pro trial has ended</h1>
    <p>
      <b>Thank you for trying out our platform!</b>
      <br/>To make sure you don't miss out on great market opportunities start your premium account for only $34.99/mo.<br/>
      If you have any issues or question please feel free to reach out <a class="mbpLink" href="mailto:hello@marketbit.pro">hello@marketbit.pro</a>.
    </p>
    <PaymentPartial :user="user"/>
  </div>
</template>

<script>
import MBPButton from "../../components/ui/controls/MBPButton";
import PaymentPartial from "./PaymentPartial";
export default {
  name: "TrialEnded",
  props: ['user'],
  components: {PaymentPartial, MBPButton}
}
</script>

<style scoped lang="scss">

@import "../../assets/style/general";
h1,p{
  color: white;
  direction: ltr;
  text-align: center;
}

p{
  font-size: 18px;
  line-height: 26px;
  direction: ltr;
  text-align: center;
}


</style>
