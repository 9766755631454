<template>
  <section class="sectionPartial">

    <Toast :message="dictionary.settingsPage.notes.dataUpdate" ref="updated"/>
    <VerifyAccountClosingModal v-if="verifyClosingOfAccountModal"
                               @approve="closeAccountApproved"
                               @close="verifyClosingOfAccountModal = false"
    />

    <chart-note :note="dictionary.settingsPage.notes.account"/>

    <form @submit.prevent="void(0)">
      <h4>{{ dictionary.settingsPage.titles.userSettings }}</h4>

      <div class="row mobile">
        <MBPInput :label="dictionary.settingsPage.account.labels.firstName" :error-note="userDetailsErrors.firstName"
                  labelId="firstName" v-model="userSettings.firstName" autocomplete="false" type="text"/>

        <MBPInput :label="dictionary.settingsPage.account.labels.lsatName" :error-note="userDetailsErrors.lastName"
                  labelId="lastName" v-model="userSettings.lastName" autocomplete="false" type="text"/>
      </div>

      <div class="row mobile">
        <MBPInput :label="dictionary.settingsPage.account.labels.email" :error-note="userDetailsErrors.email"
                  :note="userSettings.isGoogleAuth === true ? dictionary.settingsPage.notes.googleAuth : null"
                  labelId="email" v-model="userSettings.email" autocomplete="false" type="email"/>
        <MBPInput :label="dictionary.settingsPage.account.labels.password" :error-note="userDetailsErrors.password"
                  v-model="userSettings.password"
                  labelId="password" autocomplete="false"
                  :note="mustUpdatePassword ? dictionary.settingsPage.notes.updatePassword : null" type="password"/>
      </div>

      <div class="" style="margin-bottom: 1rem">
        <label style="display: block; margin-bottom: .5rem">{{ dictionary.settingsPage.account.labels.systemTheme }}</label>
        <div class="row">
        <toggle-switch
            :small="false" :state="userSettings.systemTheme === 'darkMode'" @stateChanged="userSettings.systemTheme === 'darkMode' ? userSettings.systemTheme = 'lightMode' : userSettings.systemTheme = 'darkMode'; updateTheme(userSettings.systemTheme)"
            :off-text="dictionary.settingsPage.account.labels.lightMode"
            :text="dictionary.settingsPage.account.labels.darkMode"/>
        </div>
      </div>

      <MBPButton :label="dictionary.settingsPage.buttons.saveAccount" :disabled="mustUpdatePassword || invalidEmail"
                 @click="updateUserSettings" class="action"/>
    </form>

    <form @submit.prevent="updateLanguageSettings" v-if="userSettings">
      <h4>{{ dictionary.settingsPage.titles.languageSettings }}</h4>

      <div class="mobile row" :style="systemLanguage === 'en' ? {marginBottom: '10px'} : {}">
        <DropDown
            v-if="systemLanguage"
            @select="systemLanguage = $event.value"
            :min-width="'160px'"
            :label="dictionary.settingsPage.titles.systemLanguage"
            :multi="false"
            :closeOnSelect="true"
            :full-width="false"
            :default-selected="systemLanguage"
            :options="systemLanguages"/>

        <div v-if="systemLanguage !== 'en'">
          <div style="margin: 0rem 0 .5rem">
            {{ dictionary.settingsPage.titles.newsLanguages }}
          </div>

          <div class="checkboxGrid">
            <MBPCheckbox v-for="(lang, lIdx) in systemLanguages"
                         :key="`newslang-${lang.value}`"
                         :value="lang.value"
                         :name="'newslang'"
                         :values="selectedNewsLanguages"
                         @change="changedNewsLanguages"
                         :labelId="`news-${lang.value}`" :label="lang.label"/>
          </div>
        </div>
      </div>


      <MBPButton :label="dictionary.settingsPage.buttons.saveLanguageSettings" class="action"/>
    </form>


    <form @submit.prevent="void(0);">
      <h4>{{ dictionary.settingsPage.titles.subscriptionSettings }}</h4>

      <div v-if="userSettings.status === 'subscribed'">
        <div class="subscriptionBlock">
          <label>{{ dictionary.settingsPage.account.labels.nextPayment }}</label>
          {{ userSettings.subscription?.nextPaymentDate || '--' }}
        </div>

        <div class="subscriptionBlock">
          <label>{{ dictionary.settingsPage.account.labels.subscriptionPrice }}</label>
          ${{ userSettings.subscription?.amount || '0.00' }}
        </div>

        <a :href="userSettings.subscription.updateLink" v-if="userSettings.subscription.updateLink"
           style="color: var(--highlightColor)"
           target="_blank">{{ dictionary.settingsPage.account.labels.updatePaymentCard }}</a>
      </div>

      <div v-if="userSettings.status === 'trial'">
        <div id="trialExpiration">
          <i class="ri-alert-line"/>
          <p v-html="dictionary.settingsPage.notes.expiration(userSettings.trialExpirationDate, userSettings.trialExpirationDateDays)"
             style="padding: 0; margin: 0; color: var(--red)"/>
        </div>

        <PaymentPartial :is-settings="true" :user="userSettings"/>
        <!--          <MBPButton :label="dictionary.settingsPage.buttons.subscribeNow" @click="openPayment()" class="bold action"/>-->
        <!--        <a href="#!" class="paddle_button" data-product="7934" data-theme="none">-->
        <!--        </a>-->
      </div>
    </form>

    <form @submit.prevent="void(0)">
      <h4>{{ dictionary.settingsPage.titles.dangerZone }}</h4>
      <MBPButton :label="dictionary.settingsPage.buttons.logout" @click="logout()" class="danger"/>
      <br/><br/>
      <MBPButton :label="dictionary.settingsPage.buttons.closeAccount"
                 @click="verifyClosingOfAccount()"
                 class="danger"/>
    </form>
  </section>
</template>

<script>

import UserService from '../../services/user';

import ChartNote from "../../components/charts/ChartNote";
import MBPButton from "../../components/ui/controls/MBPButton";
import MBPInput from "../../components/ui/controls/MBPInput";
import DropDown from "../../components/ui/DropDown";
import MBPCheckbox from "../../components/ui/controls/MBPCheckbox";
import VerifyAccountClosingModal from "./modals/VerifyAccountClosingModal";

import eventBus from '../../services/events'
import Toast from "../../components/ui/Toast";
import PaymentPartial from "./PaymentPartial";
import ToggleSwitch from "../../components/ui/ToggleSwitch";

export default {
  name: "AccountPartial",
  components: {
    ToggleSwitch,
    PaymentPartial, Toast, VerifyAccountClosingModal, MBPCheckbox, DropDown, MBPInput, MBPButton, ChartNote},
  props: ['userSettings'],
  data() {
    return {
      invalidEmail: false,
      mustUpdatePassword: false,
      mustChoosePasswordNote: false,
      originalEmailAddress: null,
      verifyClosingOfAccountModal: false,
      selectedNewsLanguages: [],
      systemLanguages: [{
        value: 'en',
        label: this.dictionary.languages.en
      }, {
        value: 'he',
        label: this.dictionary.languages.he
      }],
      systemLanguage: null,
      userDetailsErrors: {
        firstName: null,
        lastName: null,
        email: null,
        password: null
      }
    }
  },
  watch: {
    'userSettings.email'(newVal) {
      this.checkPasswordRequirement(newVal);
    },
    'userSettings.password'(newVal) {
      this.checkPasswordRequirement(newVal);
    }
  },
  methods: {
    updateTheme(theme) {
      eventBus.emit('themeChange', theme);
    },
    checkPasswordRequirement(emailValue) {
      if (this.userSettings.isGoogleAuth && emailValue !== this.originalEmailAddress) {

        if (!this.userSettings.password || (this.userSettings.password.trim().length === 0)) {
          this.mustUpdatePassword = true;
        } else {
          this.mustUpdatePassword = false;
        }
      } else {
        this.mustUpdatePassword = false;
      }
    },

    async updateLanguageSettings() {

      await UserService.saveUserSettings({
        systemLanguage: this.systemLanguage,
        newsLanguages: this.selectedNewsLanguages,
      }, 'languages');

      this.$refs.updated.show(() => {
      });
      eventBus.emit('changeUiLanguage', this.systemLanguage)
    },
    async updateUserSettings() {

      await UserService.saveUserSettings({
        firstName: this.userSettings.firstName,
        lastName: this.userSettings.lastName,
        email: this.userSettings.email,
        systemTheme: this.userSettings.systemTheme,
        password: this.userSettings.password
      }, 'details');

      this.$refs.updated.show();

    },
    async logout() {
      await UserService.logoutUser();
      location.href = '/'
    },
    verifyClosingOfAccount() {

      this.verifyClosingOfAccountModal = true;
    },
    changedNewsLanguages(lang) {

      const isChecked = lang.target.checked;
      if (isChecked) {
        if (!this.selectedNewsLanguages.includes(lang.target._value)) this.selectedNewsLanguages.push(lang.target._value)
      } else {
        const indexOfLang = this.selectedNewsLanguages.indexOf(lang.target._value);
        if (indexOfLang > -1) this.selectedNewsLanguages.splice(indexOfLang, 1);
      }
    },
    async closeAccountApproved() {

      try {
        await UserService.cancelSubscription();
        location.reload();
      } catch (e) {
        alert('Error. Please try again later.');
      }
    },

  },
  mounted() {
    this.originalEmailAddress = this.userSettings.email;
    this.selectedNewsLanguages = this.userSettings.newsLanguages;
    this.systemLanguage = this.userSettings.systemLanguage;
  }
}
</script>

<style scoped lang="scss">

@import "../../assets/style/general.scss";
@import "../../assets/style/controls.scss";

form {
  background: var(--bgColor);
  border-radius: 8px;
  border: 1px solid var(--notficationBorderColor);
  padding: $minSpacing;
  box-shadow: 0 2px 2px -10px rgba(0, 0, 0, 0.02), 0 5px 15px 0 rgba(0, 0, 0, 0.05);
  margin-bottom: 2rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  h4 {
    margin: ($minSpacing * -1) -5px 0;
    transform: translateY(-55%);
    background: var(--bgColor);
    width: fit-content;
    padding: 0 5px;
  }
}

.subscriptionBlock {
  &:last-of-type {
    margin-bottom: 1rem;

  }

  padding: 10px;
  border-bottom: 1px solid var(--notficationBorderColor);

  label {
    font-weight: 500;
  }
}

#trialExpiration {
  color: var(--red);
  font-size: 20px;
  margin-bottom: 1rem;
  gap: 10px;
  display: flex;
  align-items: center;
}

</style>
