<template>
  <th :class="{center}" :style="setStyle()">
    <div class="sort" :style="{cursor: sortValue ? 'pointer' : 'default'}" @click="$emit('sort')" :dir="uiDirection">
      <slot/>
      <i class="ri-arrow-down-s-line sortSign" :class="{active: sortKey === sortValue}" v-if="sortDirection === -1 && sortValue"/>
      <i class="ri-arrow-up-s-line sortSign" :class="{active: sortKey === sortValue}" v-if="sortDirection === 1 && sortValue"/>
    </div>
  </th>
</template>

<script>
export default {
  name: "HeadCell",
  props: ['sortKey', 'sortDirection', 'sortValue', 'center', 'width'],
  methods: {
    setStyle () {
      return this.width ? {width: `${this.width}px`, overflow: 'hidden'} : {};
    }
  }
}
</script>


<style scoped lang="scss">

th {
  user-select: none;
  border-bottom: 2px solid var(--notficationBorderColor);
  background: var(--bgColor) !important;
}

.center {
  text-align: center !important;
}

.sort {
  padding: 5px 10px;
  display: inline-flex;
  align-items: center;
  gap: 10px;
}

.sortSign {
  color: #aaa;
  opacity: .3;
}

.active {
  color: var(--activeSortSignColor);
  opacity: 1;
}
</style>
