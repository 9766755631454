<template>
  <div>
    <BigLoader v-if="isPageLoading"/>
    <empty-portfolio
        v-if="!watchlist || (watchlist?.watchlist?.length === 0 && (type !== 'news' && type !== 'symbolPage'))"/>

    <section v-if="!isPageLoading && (watchlist?.watchlist?.length > 0 || (type === 'news' || type === 'symbolPage'))">

      <div class="tabs scroll" :class="{hasTabs}" v-if="!isPageLoading && showFilters">
        <div class="tab" @click="filterNotifications('all')" :class="{active: activeFilter.includes('all')}">
          <i class="ri-check-line" v-if="activeFilter.includes('all')"/>
          {{ dictionary.feeds.filters.all }}
        </div>
        <div class="tab" @click="filterNotifications('pr')" :class="{active: activeFilter.includes('pr')}">
          <i class="ri-check-line" v-if="activeFilter.includes('pr')"/>
          {{ dictionary.feeds.filters.pr }}
        </div>
        <div class="tab" @click="filterNotifications('SEC')" :class="{active: activeFilter.includes('SEC')}">
          <i class="ri-check-line" v-if="activeFilter.includes('SEC')"/>
          {{ dictionary.feeds.filters.sec }}
        </div>
        <div class="tab" @click="filterNotifications('priceTargets')" :class="{active: activeFilter.includes('priceTargets')}">
          <i class="ri-check-line" v-if="activeFilter.includes('priceTargets')"/>
          {{ dictionary.feeds.filters.priceTargets }}
        </div>
        <div class="tab" @click="filterNotifications('financial_media')"
             :class="{active: activeFilter.includes('financial_media')}">
          <i class="ri-check-line" v-if="activeFilter.includes('financial_media')"/>
          {{ dictionary.feeds.filters.financialMedia }}
        </div>
        <div class="tab" @click="filterNotifications('bz')" :class="{active: activeFilter.includes('bz')}">
          <i class="ri-check-line" v-if="activeFilter.includes('bz')"/>
          {{ dictionary.feeds.filters.analysts }}
        </div>
      </div>

      <div class="intersection" @scroll="handleScroll" :key="feedKey" v-if="!isPageLoading">
        <div v-for="(day, dayI) in groupNotificationsByDate" :key="day.date">
          <h2 class="stickyDate" :class="{withFilters: showFilters, withTabs: hasTabs}">{{ day.format }}</h2>
          <div :ref="`notification-${nIdx}`" v-for="(notification, nIdx) in day.news"
               :key="`notification-w-${notification.ID}`" class="notification">
            <Notification
                :type="this.type === 'news' ? 'news' : 'equity'"
                :data-uuid="notification.ID"
                :notification="notification"
                :hideEventsBySymbols="hideEventsBySymbols"
                :data-symbols="notification.symbols.map(symbol => symbol.symbol).join(',')"
                :prices="prices"
                :key="`notification-${notification.ID}`"/>
          </div>
        </div>


        <div :class="type === 'symbol' ? 'intSymbol' : type === 'equity' ? 'int' : 'news'"></div>
      </div>

      <div class="loadMore" v-if="lastRequestState.hasNextPage" @click="loadNext">טעינת הודעות נוספות</div>
    </section>

  </div>
</template>

<script>

import intersectionObserver from '../composables/intersectionObserver';

const {observedElement, elementClass, interOptions, interCallback, observer, callback} = intersectionObserver();
import {useRoute} from "vue-router";
import {DateTime} from 'luxon';
import NewsService from '@/services/news';
import Notification from "../components/feed/notification/Notification";
import {getCurrentInstance, ref} from 'vue'

import {useWatchlist} from "../stores/watchlist";
import BigLoader from "../components/ui/BigLoader";
import eventBus from "../services/events";
import EmptyPortfolio from "../components/ui/EmptyPortfolio";

export default {
  name: 'Feed',
  emits: ['joinFilteredFeed', 'feedLoaded', 'toggleNotifications'],
  props: ['prices', 'symbols', 'type', 'symbol', 'showFilters', 'hasTabs', 'hideEventsBySymbols'],
  data() {
    return {
      watchlist: useWatchlist(),
      activeFilter: ['all'],
      notificationsIds: new Set(),
      latestNotifications: [],
      feedKey: 0,
      isPageLoading: true,
      hasObserver: new Set(),
      observer: null,
      refreshInterval: null,
      news: [],
      lastRequestState: {
        currentPage: 0
      }
    }
  },
  setup() {

    const app = getCurrentInstance();
    app.ctx.watchlist = useWatchlist();

    app.ctx.watchlist.$subscribe((mutation, state) => {

      app.ctx.loadFeed(true);
    });
  },
  beforeUnmount() {
    clearInterval(this.refreshInterval);
  },
  async mounted() {

    this.refreshInterval = setInterval(() => {

      this.loadFeed(true).then().catch();
    }, 15 * 60 * 1000);

    let observerOptions = {
      rootMargin: '0px',
      threshold: 0
    }
    this.observer = new IntersectionObserver(this.observerCallback, observerOptions);
    const {access} = useRoute().query;
    this.setDesign(access);
    await this.loadFeed();

    eventBus.on('mainViewScrolledToBottom', this.loadNext);


    // if (this.type === 'equity' || this.type === 'sector') {
    if (this.type === 'equity' || this.type === 'neutral') {
      eventBus.on('newEquityNotification', this.handleNewEquityNotification);
    } else if (this.type === 'symbol') {
      eventBus.on('newEquityNotification', this.handleNewSymbolNotification)
    } else if (this.type === 'news') {
      eventBus.on('newNewsNotification', this.handleNewNewsNotification)
    }
  },
  watch: {
    // type(current, old) {
    //
    //   if (current && (current !== old)) {
    //
    //     this.loadFeed(true);
    //   }
    // },
    // symbols(current, old) {
    //
    //   if (current && (current !== old)) {
    //
    //     this.loadFeed(true);
    //   }
    // }
  },
  computed: {
    groupNotificationsByDate() {

      let dates = {};

      this.news.forEach(notifcation => {

        const dateString = DateTime.fromISO(notifcation.datetime).setLocale(this.dateFormatLocale).toFormat('yyyy-MM-dd');
        const dateFormat = DateTime.fromISO(notifcation.datetime).setLocale(this.dateFormatLocale).toFormat('DD');

        if (!dates[dateString]) {
          dates[dateString] = {
            date: dateString,
            format: dateFormat,
            news: []
          };
        }

        dates[dateString].news.push(notifcation);
      });

      return Object.values(dates);
    }
  },
  methods: {
    filterNotifications(filterName) {

      if (filterName === 'all') {

        this.activeFilter = ['all'];
      } else {

        const indexOf = this.activeFilter.indexOf(filterName);
        if (indexOf === -1) {
          this.activeFilter.push(filterName);
        } else {
          this.activeFilter.splice(indexOf, 1);
        }

        const indexOfAll = this.activeFilter.indexOf('all');
        if (indexOfAll > -1) this.activeFilter.splice(indexOfAll, 1);
      }

      if (this.activeFilter.length === 0) this.activeFilter = ['all'];
      this.loadFeed(true);
    },
    handleNewSymbolNotification(notification) {

      let isRelated = notification.symbols.find(symbol => symbol.symbol === this.symbol);
      if (isRelated) {

      }
    },
    handleNewNewsNotification(notification) {

      if (!this.notificationsIds.has(notification.ID)) {
        this.notificationsIds.add(notification.ID);
        this.news.unshift(notification);

        this.$nextTick(() => {

          setTimeout(() => {
            const element = document.querySelector(`[data-uuid="${notification.ID}"]`);

            if (element) {
              element.classList.add('newMessage')

              setTimeout(() => {

                element.classList.remove('newMessage');
              }, 10100);
            }
          }, 400);
        })
      }
    },
    handleNewEquityNotification(notification) {

      if (this.watchlist.watchlist && this.watchlist.watchlist.length > 0 && !notification.symbols.some(symbol => this.watchlist.watchlist.includes(symbol.symbol))) return;

      if (!this.activeFilter.includes('all')) {

        const shouldBlockNotification = this.shouldBlockNotificationByFilter(notification);
        if (shouldBlockNotification) return;
      }

      this.news.unshift(notification);

      this.$nextTick(() => {
        const element = document.querySelector(`[data-uuid="${notification.ID}"]`);

        setTimeout(() => {
          if (element) {
            element.classList.add('newMessage')

            setTimeout(() => {

              element.classList.remove('newMessage');
            }, 10100);
          }
        }, 400);

        this.observer.observe(element);
        this.hasObserver.add(notification.ID);
      })
    },
    shouldBlockNotificationByFilter(notification) {

      let activeFilters = {};

      if (this.activeFilter.includes('SEC')) {
        activeFilters.sec = notification.sourceName !== 'SEC' && !notification.tags.includes('sec') && notification.sourceType !== 'SEC';
      }

      if (this.activeFilter.includes('pr')) {
        activeFilters.pr = notification.sourceType !== 'pr';
      }

      if (this.activeFilter.includes('financial_media')) {
        activeFilters.financial_media = notification.sourceType !== 'financial_media';
      }

      if (this.activeFilter.includes('bz')) {
        activeFilters.bz = notification.sourceType !== 'bz' && notification.sourceType !== 'bz_wire';
      }


      return Object.values(activeFilters).every(value => value === true);
    },
    async loadNext() {
      if (this.lastRequestState.hasNextPage) {

        this.lastRequestState.currentPage += 1;
        await this.loadFeed();
      }
    },
    async loadFeed(shouldReset = false) {

      if (this.watchlist?.watchlist?.length === 0 && (this.type !== 'news' && this.type !== 'symbolPage')) {
        this.isPageLoading = false;
        return;
      }

      if (shouldReset) {
        this.news = [];

        this.lastRequestState = {
          currentPage: 0
        }
      }

      if (this.lastRequestState.currentPage === 0) {
        this.isPageLoading = true;
      }

      let data = null;

      if (this.type !== 'news' && this.$route.name !== 'Macro' && this.type !== 'filteredFeed') {

        if ((this.symbols && this.symbols.length > 0)) {
          // if ((this.symbols && this.symbols.length > 0) || (this.$route.params.symbol)) {

          let query = {page: this.lastRequestState.currentPage};
          if (this.activeFilter !== 'all') query.sourceType = this.activeFilter.join(',');

          if (this.type !== 'sector') {
            data = await NewsService.newsBySymbols({
              symbols: this.symbols,
              query
            });
          } else {
            // data = await NewsService.getSectorFeed(this.$route.params.symbol || this.symbols,{
            //   query
            // });
          }

        } else {

          let query = {page: this.lastRequestState.currentPage};
          if (this.activeFilter !== 'all') query.sourceType = this.activeFilter.join(',');
          data = await NewsService.getFeed(query);
        }

      } else if (this.type === 'filteredFeed') {

        data = await NewsService.getFeedByFilterId(this.$route.params.feedId);
        this.$emit('joinFilteredFeed', this.$route.params.feedId);

      }else {

        data = await NewsService.macroNews({query: {page: this.lastRequestState.currentPage}})
      }
      // const data = await NewsService.getFeed({page: this.lastRequestState.currentPage})
      this.news = this.news.concat(data.news);

      this.lastRequestState = {
        currentPage: this.lastRequestState.currentPage,
        hasNextPage: this.lastRequestState.currentPage < data.pages,
        pages: data.pages,
      }


      this.isPageLoading = false;

      let target = '.notification';
      document.querySelectorAll(target).forEach((i) => {

        let notificationId = i.dataset.uuid;
        if (i && !this.hasObserver.has(notificationId)) {
          this.notificationsIds.add(notificationId)
          this.hasObserver.add(notificationId);
          this.observer.observe(i);
        }
      });


      this.feedLoaded();
      // this.feedKey++;
    },
    observerCallback(entries, observer) {
      let quotesForSymbols = new Set();

      entries.forEach(entry => {
        if (entry.isIntersecting) {

          let symbols = entry.target.dataset.symbols;
          if (symbols) {

            symbols.split(/\,/g).forEach(symbol => quotesForSymbols.add(symbol));
          }
        }
      });

      this.$emit('feedLoaded', Array.from(quotesForSymbols));
    },
    feedLoaded() {

      const symbols = this.news.reduce((final, current) => {

        current.symbols.forEach(symbol => final.add(symbol.symbol));
        return final;
      }, new Set());

      this.$emit('feedLoaded', Array.from(symbols));
    },
    setDesign(accessCode) {
      if (accessCode === '1') {
        document.documentElement.style.setProperty('--headlineColor', '#00f');
        document.documentElement.style.setProperty('--dir', 'rtl');
      }
    }
  },
  components: {EmptyPortfolio, BigLoader, Notification}
}
</script>

<style lang="scss">

.stickyDate {
  position: sticky;
  top: 0;
  background: var(--tableHoverColorOpacity);
  backdrop-filter: blur(20px);
  padding: .35rem 1rem;
  border-bottom: 1px solid var(--notficationBorderColor);
  box-shadow: 0 5px 5px 0 rgba(15, 15, 15, .05);
  margin: 0;
  font-size: 18px;
  z-index: 1;
}

.newMessage {
  animation-name: newMessage;
  animation-duration: 10s;
}

.loadMore {
  background: var(--highlightColorOpacity);
  color: var(--highlightColor);
  text-align: center;
  padding: 10px;
  font-weight: 600;
  border-radius: 8px;
  margin: 1rem;
  @media (min-width: 660px) {
    display: none;
  }
}

@keyframes newMessage {
  0% {
    background-color: inherit;
  }
  30% {
    background-color: #fff8ef;
  }
  100% {
    background-color: inherit;
  }
}


.tabs {
  border-top: 1px solid var(--notficationBorderColor);
  z-index: 10;
  &.hasTabs {
    top: 44px;
  }
}

.withFilters {
  top: 34px;
  &.withTabs {
    top: 78px;
  }
}

</style>
