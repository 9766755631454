<template>

  <section id="symbolData">
    <big-loader v-if="!symbolInfo"/>

    <div id="main">
      <div>
        <div id="symbolInfo" v-if="symbolInfo" ref="symbolTopInfo">
          <div id="logo" :class="{noLogo: hideLogo}">
            <img :src="logo" ref="logo" v-if="!hideLogo"/>
            <span id="ticker" v-if="hideLogo">{{ symbol }}</span>
          </div>
          <div>
            <div class="symbolHeader">
              <h2>{{ symbolInfo.symbolInfo.exchange }}:{{ symbolInfo.symbolInfo.symbol }}</h2>

            </div>
            <h3>{{ symbolInfo.symbolInfo.name }}</h3>
            <p id="symbolPriceRow">
              <SymbolLabel
                  :symbol="{symbol: symbol, price: symbolInfo.prices.price, changeP: symbolInfo.prices.changeP}"
                  :prices="{price: {price: symbolInfo.prices.price, changeP: symbolInfo.prices.changeP}}"
                  :pre-market-quote="symbolInfo.prices.preMarket"
                  :post-market-quote="symbolInfo.prices.postMarket"
                  :dont-link="true" :always-on="true" :price-only="true"/> &bull;
              {{ symbolInfo.symbolInfo.industry }} &bull;
              {{ symbolInfo.symbolInfo.sector }}
            </p>
          </div>
        </div>
        <div v-if="symbolInfo" class="notificationTags">
          <div class="addRemoveSymbol add" @click="addRemoveWatchlistSymbol(symbol)"
               v-if="!watchlist || !watchlist.watchlist.includes(symbol)">
            {{ dictionary.buttons.addToPortfolio }}
          </div>
          <div class="addRemoveSymbol remove" @click="addRemoveWatchlistSymbol(symbol)"
               v-if="watchlist && watchlist.watchlist.includes(symbol)">
            {{ dictionary.buttons.removeFromPortfolio }}
          </div>
          <div v-for="(event, eIndx) in symbolInfo.events" class="tag event"
               :class="{past: event.when && event.when === 'yesterday'}">
            <i class="ri-calendar-line"/>
            {{ event.label }}{{ extraEventData(event) }}
            <div v-if="event.dividendRate" class="eventInfo">
              <i class="ri-question-mark" @click="divToolTipOn = !divToolTipOn"/>
              <div class="tooltipExplanation" v-if="divToolTipOn">

                <i class="ri-close-line" @click="divToolTipOn = false"/>
                {{ dictionary.messages.exDiv }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="seeFull" v-if="isPreview" @click="closePreview()">
        <router-link :to="`/symbol/${symbol}`"/>
        {{dictionary.buttons.seeFullDetails}}
      </div>

      <div id="prices" v-if="symbolInfo && equityPrice">
        <div class="priceRow">
          <SymbolPagePriceBlock :title="symbolPageLabels.pe" :price="equityPrice.pe"/>
          <SymbolPagePriceBlock :title="symbolPageLabels.eps" :tooltip="dictionary.symbolPage.epsNote"
                                :price="equityPrice.eps"/>
          <SymbolPagePriceBlock :title="symbolPageLabels.marketCap"
                                :price="formatNumber(equityPrice.marketCap, '$0,0.00a')"/>
        </div>

        <div class="priceRow">


          <SymbolPagePriceBlock :title="symbolPageLabels.volume"
                                :price="formatNumber(equityPrice.volume, '0,0')"/>
          <SymbolPagePriceBlock :title="symbolPageLabels.avgVolume"
                                :price="formatNumber(equityPrice.avgVolume, '0,0')"/>
          <SymbolPagePriceBlock :title="symbolPageLabels.sharesOutstanding"
                                :price="formatNumber(equityPrice.sharesOutstanding, '0,0')"/>
        </div>

        <div class="priceRow">
          <SymbolPagePriceBlock :title="symbolPageLabels.dailyRange"
                                :price="formatNumber(valueExists(equityPrice.dayLow, equityPrice.dayHigh), '0,0.00')"/>
          <SymbolPagePriceBlock :title="symbolPageLabels.fiftyTwoWeeks"
                                :price="formatNumber(valueExists(equityPrice.yearLow, equityPrice.yearHigh), '0,0.00')"/>
          <SymbolPagePriceBlock :title="symbolPageLabels.previousClose" :price="symbolInfo.prices.previousClose"/>
        </div>

        <div class="aboutTheCompany"
             v-if="symbolInfo.symbolInfo?.description && symbolInfo.symbolInfo.description.trim().length > 0">
          <h3>{{ dictionary.symbolPage.about }}</h3>
          <p class="description" :dir="uiDirection">{{ companyDescription }} <span
              class="button label inline small bold" @click="showFullDescription = !showFullDescription;">
            {{ showFullDescription ? dictionary.buttons.readLess : dictionary.buttons.readMore }}
          </span></p>
        </div>

<!--        <div class="findPeers" v-if="!wasPeersLoaded">-->
<!--          <div v-if="!isLoadingPeers">-->
<!--            <h4><i class="ri-search-line"/> {{ dictionary.symbolPage.findMoreStocks }}</h4>-->
<!--            <p v-html="dictionary.symbolPage.findMoreStocksText(this.symbol)"/>-->

<!--            <button @click="findInterestingStocks()">{{ dictionary.symbolPage.clickToFindMoreStocks }}</button>-->
<!--          </div>-->
<!--          <big-loader :transparentBg="true" v-else/>-->
<!--        </div>-->


        <peers-partial :symbol="symbol" :peers="peers" :isPreview="isPreview" :symbol-page-labels="symbolPageLabels" :isDev="isDev"/>

        <div style="margin: 2rem 0">
          <h3>{{ dictionary.symbolPage.performanceOverTime }}</h3>
          <PriceChange :price-change="symbolInfo.priceChange"/>
        </div>
        <SymbolBigPriceChart :symbol="symbol" :current-price="equityPrice.price" :showLegend="false" :periods="true"
                             :defaultChart="'line'"/>
      </div>
    </div>


  </section>
</template>

<script>
import SymbolPagePriceBlock from "../../components/symbols/symbolPagePriceBlock";
import BigLoader from "../../components/ui/BigLoader";
import numeral from "numeral";
import {useNewYorkTime} from "../../stores/newYorkTime";
import {getCurrentInstance, onMounted} from "vue";
import extraEventData from "../../composables/eventTagExtraData";
import SymbolService from "../../services/symbols";
import SymbolLabel from "../../components/symbols/symbol";
import SymbolBigPriceChart from "../../components/charts/SymbolBigPriceChart";

import intersectionObserver from '../../composables/intersectionObserver';
import UserService from "../../services/user";
import PriceChange from "./PriceChange";
import ChartNote from "../../components/charts/ChartNote";
import SymbolLogo from "../../components/symbols/symbolLogo";
import PeersPartial from "./PeersPartial";
import eventBus from "../../services/events";

const {observer} = intersectionObserver();

export default {
  name: "OverviewPartial",
  components: {
    PeersPartial,
    SymbolLogo, ChartNote, PriceChange, SymbolBigPriceChart, SymbolLabel, BigLoader, SymbolPagePriceBlock},
  props: ['symbol', 'isPreview', 'equityPrice', 'watchlist', 'peers','isDev'],
  data() {
    return {
      logo: null,
      isLoadingPeers: false,
      wasPeersLoaded: true,
      refreshPageDataInterval: null,
      showFullDescription: false,
      hideLogo: false,
      symbolInfo: null,
      observer: null,
      symbolPageLabels: this.dictionary.symbolPage,
    }
  },
  setup() {

    const nyTime = useNewYorkTime();
    const app = getCurrentInstance();

    function startDataRefresh() {

      if (!app.ctx.refreshPageDataInterval) {

        app.ctx.refreshPageDataInterval = setInterval(() => {
          app.ctx.getSymbolDetails();
        }, 60000);
      }
    }

    onMounted(() => {

      setTimeout(() => {
        if (nyTime.isMarketOpen) {

          if (app.refs.chart) {
            startDataRefresh();
          } else {
            setTimeout(() => {

              startDataRefresh();
            }, 5000);
          }
        }
      }, 10000);
    });

    const defaultPeriod = nyTime.isMarketOpen ? '1d' : '1m'
    return {extraEventData, nyTime, defaultPeriod}
  },
  methods: {
    closePreview () {
      eventBus.emit('showChangeableContent', null);
    },
    findInterestingStocks() {
      this.isLoadingPeers = true;
      setTimeout(() => {

        this.isLoadingPeers = false;
        this.wasPeersLoaded = true;
      }, Math.random() * (4000 - 2000) + 2000);
    },
    setLogoBg(symbol) {

      return {backgroundImage: `url(https://financialmodelingprep.com/image-stock/${symbol}.png)`}
    },
    async getSymbolDetails() {

      this.symbolInfo = await SymbolService.getSymbolInfo(this.symbol);
      this.$nextTick(() => {
        if (this.$refs.symbolTopInfo) {
          this.observer.observe(this.$refs.symbolTopInfo);
        }
      });
    },
    async addRemoveWatchlistSymbol(symbol) {

      if (this.watchlist.watchlist.includes(symbol)) {

        try {
          mixpanel.track('symbol:remove', {client: window.location.host, symbol: symbol});
        } catch (e) {
        }

        const watchlist = await UserService.removeSymbolFromWatchlist(symbol)
        this.watchlist.updateWatchlist(watchlist);
      } else {

        try {
          mixpanel.track('symbol:add', {client: window.location.host, symbol: symbol});
        } catch (e) {
        }
        const watchlist = await UserService.addSymbolToWatchlist(symbol)
        this.watchlist.updateWatchlist(watchlist);
      }
    },
    setLogo() {
      let loadImage = new Image();
      loadImage.crossOrigin = 'anonymous'
      loadImage.style.width = '100%';

      loadImage.onload = () => {
        this.logo = `https://financialmodelingprep.com/image-stock/${this.symbol}.png`
        setTimeout(() => {
        }, 1);
      };

      loadImage.onerror = () => {
        this.hideLogo = true;
      }

      loadImage.src = `https://financialmodelingprep.com/image-stock/${this.symbol}.png`;
    },
    formatNumber(value, format) {
      if (value === null || value === undefined) return '-';
      if (value.toString().includes(' - ')) {

        let splitRange = value.split(' - ').map(valuePart => numeral(valuePart).format(format))
        return splitRange.join(' - ')
      }
      return numeral(value).format(format).toUpperCase()
    },
    valueExists() {
      const args = [...arguments];
      if (args.some(argument => argument === null || argument === undefined)) {

        return '-'
      } else {
        return args.join(' - ')
      }
    },
    observerCallback(entries, observer) {

      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.$emit('scrolledUp')
        } else {
          this.$emit('scrolledDown')

        }
      });

      this.$emit('feedLoaded');
    },
  },
  computed: {
    companyDescription() {

      if (!this.symbolInfo?.symbolInfo?.description) return '';
      return !this.showFullDescription ? this.symbolInfo.symbolInfo.description.split(/[ ]/g).splice(0, 50).join(' ') + '...' : this.symbolInfo.symbolInfo.description;
    }
  },
  async mounted() {


    let observerOptions = {
      rootMargin: '0px',
      threshold: 0
    }
    this.observer = new IntersectionObserver(this.observerCallback, observerOptions);
    this.getSymbolDetails();
    this.setLogo();
  },
}
</script>

<style scoped lang="scss">

@import "../../assets/style/vars";
@import "../../assets/style/notification";
@import "../../assets/style/controls";

#main {
  padding: $minSpacing;
}


.priceRow {
  display: flex;
  gap: $minSpacing;
  margin-bottom: $minSpacing;

  &:last-of-type {
    margin-bottom: 0;
  }
}

#symbolHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--notficationBorderColor);
  @media(max-width: 660px) {
    flex-direction: column;
  }
}

#symbolInfo {
  margin-bottom: $minSpacing * 2.5;
  display: flex;
  align-items: flex-start;
  gap: 1rem;

  #symbolPriceRow {
    display: flex;
    align-items: center;
    gap: 5px;
    @media(max-width: 660px) {
      align-items: flex-start;
      flex-direction: column;
    }
  }

  #ticker {
    font-weight: bold;
  }

  #logo {
    border-radius: 8px;
    background: #626262;
    padding: 12px;
    box-sizing: border-box;
    width: 84px;
    height: 94px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
    }
  }

  h2 {
    margin-top: 0;
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 24px;
  }

  h3 {
    margin: 0;
    font-size: 20px;
    font-weight: 500;

  }

  p {
    margin: $smallGap 0;
  }
}

.noLogo {
  background: var(--highlightColorOpacity) !important;
  color: var(--highlightColor) !important;
}

.brightLogo {
  background: #1a1a1a !important;
}

#symbolPage[dir="rtl"] {
  #symbolInfo {
    h2, h3 {
      direction: rtl;
      text-align: right;
    }
  }
}

.symbolHeader {
  display: flex;
  gap: 1rem;
  align-items: center;
}


.addRemoveSymbol {
  font-size: 17px;
}

.notificationTags {
  margin: -25px 0 25px;
  justify-content: center;

  .tag {
    display: flex;
    align-items: center;
    gap: 5px;
    margin: unset;
  }
}

.eventInfo {
  position: relative;
  background: var(--lightYellow);
  border-radius: 50%;
  width: 15px;
  color: black;
  text-align: center;
  font-weight: 400;
  font-size: 12px;
}

.tooltipExplanation {
  position: absolute;
  width: 190px;
  z-index: 3;
  background: var(--bgColor);
  left: 50%;
  transform: translateX(-50%);
  top: 30px;
  font-size: 14px;
  padding: 5px;
  border-radius: 8px;
  box-shadow: 0 5px 25px 0 rgba(15, 15, 15, .15);

  .ri-close-line {
    background: black;
    border-radius: 50%;
    color: white;
    cursor: pointer;
    font-size: 16px;
  }
}

.aboutTheCompany {

  h3 {
    margin-bottom: 1rem;
    padding: 0;
  }

  .description {
    line-height: 24px;
  }

  .description[dir="rtl"] {
    direction: rtl;
    text-align: right;
  }
}


.seeFull {
  background: var(--lightYellow);
  color: var(--bgColor);
  border-radius: 8px;
  padding: 5px;
  text-align: center;
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 1rem;
  position: relative;
  a{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
