<template>

  <div class="priceBlock" :dir="uiDirection">

    <div class="priceBlockTitle">{{ title }}</div>
    <div class="priceBlockPrice">
      <Popper
          arrow
          :hover="true"
          :content="tooltip"
          v-if="tooltip"
      >
        <div class="tooltipIcon">
          <i class="ri-information-line"/>
        </div>
      </Popper>

      {{ price }}

    </div>



  </div>
</template>

<script>

import Popper from "vue3-popper";

export default {
  name: "symbolPagePriceBlock",
  props: ['title', 'price', 'tooltip'],
  components: { Popper }
}
</script>

<style scoped lang="scss">

@import "../../assets/style/general";

.priceBlock {
  padding: 10px;
  width: 100%;
  background: var(--tableHoverColor);
  border-radius: 8px;

  .priceBlockTitle {
    font-weight: 500;
  }

  .priceBlockPrice {
    font-size: 18px;
    font-weight: 600;
    unicode-bidi: plaintext;
  }
}

.priceBlock[dir="rtl"] {
  direction: rtl;
  text-align: right;
}
</style>
