<template>
  <main id="symbolPage" :dir="uiDirection" :class="{preview: isPreview}">

    <div id="pageTabs" v-if="equityPrice">
      <div id="miniEquity" :class="{showMiniEquity: showMiniEquity}" @click="tabClick('overview')">
        <div id="miniLogo">
          <img :src="logo" ref="logo" v-if="!hideLogo"/>
        </div>

        {{ symbol }}
        <SymbolLabel
            :symbol="{symbol: symbol, price: equityPrice.price, changeP: equityPrice.changeP}"
            :prices="{price: {price: equityPrice.price, changeP: equityPrice.changeP}}"
            :pre-market-quote="prices.preMarket"
            :post-market-quote="prices.postMarket"
            :small="true"
            :dont-link="true" :always-on="true" :price-only="true"/>
      </div>

      <div class="tabs round scroll border">
        <div class="tab" @click="tabClick('overview')" :class="{active: activeTab === 'overview'}">
          <i class="ri-folder-open-line"/>
          {{ dictionary.symbolPage.tabs.overview }}
        </div>

        <div class="tab" @click="tabClick('chart')" v-if="!isPreview" :class="{active: activeTab === 'chart'}">
          <i class="ri-line-chart-line"/>
          {{ dictionary.symbolPage.tabs.chart }}
        </div>

        <div class="tab" @click="tabClick('news')" :class="{active: activeTab === 'news'}">
          <i class="ri-newspaper-line"/>
          {{ dictionary.symbolPage.tabs.news }}
        </div>
        <div class="tab" @click="tabClick('performance')" :class="{active: activeTab === 'performance'}">
          <i class="ri-bar-chart-2-line"/>
          {{ dictionary.symbolPage.tabs.performance }}
        </div>
        <div class="tab" @click="tabClick('financials')"  v-if="!isPreview" :class="{active: activeTab === 'financials'}">
          <i class="ri-file-list-2-line"/>
          {{ dictionary.symbolPage.tabs.financials }}
        </div>
        <div class="tab" @click="tabClick('insiders')"  v-if="!isPreview" :class="{active: activeTab === 'insiders'}">
          <i class="ri-briefcase-4-line"/>
          {{ dictionary.symbolPage.tabs.insiders }}
        </div>
      </div>
    </div>

    <OverviewPartial v-if="activeTab === 'overview'"
                     @scrolledUp="showMiniEquity = false"
                     @scrolledDown="showMiniEquity = true"
                     :symbol="symbol"
                     :is-preview="isPreview"
                     :isDev="this.$route.name === 'DevSymbol'"
                     :peers="peers"
                     :watchlist="watchlist"
                     :equityPrice="equityPrice"
                     :symbolInfo="symbolInfo"/>

    <ChartPartial v-if="activeTab === 'chart'" :equityPrice="equityPrice"
                  :symbolInfo="symbolInfo"
                  :symbol="symbol"/>

    <PerformancePartial v-if="activeTab === 'performance'" :equityPrice="equityPrice" :symbol="symbol"/>

    <FinancialsPartial v-if="activeTab === 'financials'" :equityPrice="equityPrice" :symbol="symbol"/>

    <InsidersPartial v-if="activeTab === 'insiders'" :symbol="symbol"/>

    <Feed :type="'symbolPage'"
          v-if="activeTab === 'news'"
          ref="symbolFeed"
          key="symbolFeed"
          :has-tabs="true"
          :hideEventsBySymbols="[symbol]"
          :showFilters="true" :prices="prices.prices"
          @feedLoaded="()=>{}" :symbols="[symbol]"/>
  </main>

</template>

<script>

import SymbolPagePriceBlock from "../components/symbols/symbolPagePriceBlock";
import eventBus from "../services/events";

import {useWatchlist} from "../stores/watchlist";
import {useEquityPrices} from "../stores/equityPrices";
import Feed from "./FeedView";

import UserService from "../services/user";
import mixpanel from 'mixpanel-browser';
import BigLoader from "../components/ui/BigLoader";
import InsidersPartial from "./symbolView/InsidersPartial";
import PerformancePartial from "./symbolView/PerformancePartial";
import FinancialsPartial from "./symbolView/FinancialsPartial";
import SymbolBigPriceChart from "../components/charts/SymbolBigPriceChart";
import SymbolLabel from "../components/symbols/symbol";
import OverviewPartial from "./symbolView/OverviewPartial";
import ChartPartial from "./symbolView/ChartPartial";
import SymbolService from "../services/symbols";

export default {
  name: "SymbolView",
  components: {
    ChartPartial,
    OverviewPartial,
    SymbolLabel,
    SymbolBigPriceChart,
    FinancialsPartial,
    PerformancePartial,
    InsidersPartial,
    BigLoader,
    Feed,
    SymbolPagePriceBlock
  },
  props: ['prices', 'isPreview', 'previewSymbol'],
  data() {
    return {
      activeTab: 'overview',
      companyEarningsChart: null,
      divToolTipOn: false,
      symbolInfo: null,
      showMiniEquity: false,
      logo: null,
      hideLogo: null,
      observer: null,
      news: [],
      peers: [],
      watchlist: useWatchlist(),
      symbol: this.isPreview ? this.previewSymbol : this.$route.params.symbol
    }
  },
  computed: {
    equityPrice() {

      return this.equityPrices.prices[this.symbol] || this.symbolInfo;
    }
  },
  methods: {
    tabClick(tab) {

      this.showMiniEquity = tab !== 'overview';
      this.activeTab = tab;
    },
    async getSymbolPeers() {
      this.peers = this.$route.name === 'DevSymbol' ? await SymbolService.getSymbolPeersDev(this.symbol) : await SymbolService.getSymbolPeers(this.symbol);
    },
    setLogo() {
      let loadImage = new Image();
      loadImage.crossOrigin = 'anonymous'
      loadImage.style.width = '100%';

      loadImage.onload = () => {
        this.logo = `https://financialmodelingprep.com/image-stock/${this.symbol}.png`
        setTimeout(() => {
        }, 1);
      };

      loadImage.onerror = () => {
        this.hideLogo = true;
      }

      loadImage.src = `https://financialmodelingprep.com/image-stock/${this.symbol}.png`;
    },
    loadNext() {

      // this.$refs.symbolFeed.loadNext();
    },


  },
  created() {

    setTimeout(() => {
      this.$nextTick(() => {
        this.$.appContext.config.socket.joinSocketRooms([this.symbol]);

        setTimeout(() => {
          if (!this.equityPrice) {
            this.$.appContext.config.socket.joinSocketRooms([this.symbol]);
          }
        }, 2000)
      })
    }, 1);
  },
  setup() {

    const equityPrices = useEquityPrices();
    equityPrices.$subscribe(() => {
    });
    return {equityPrices};
  },
  async mounted() {


    this.$nextTick(async () => {
      this.setLogo();
      const infoRequest = await Promise.all([this.getSymbolPeers(), SymbolService.getSymbolInfo(this.symbol)]);
      this.symbolInfo = infoRequest[1];
      this.isLoading = false;


      const {tab} = this.$route.query;
      if (tab) this.activeTab = tab;

      eventBus.on('mainViewScrolledToBottom', this.loadNext);
    })

  },
  beforeUnmount() {
    eventBus.off('mainViewScrolledToBottom', this.loadNext);
  },
}
</script>

<style scoped lang="scss">

@import "../assets/style/vars";
@import "../assets/style/notification";
@import "../assets/style/controls";

.preview {
  background: var(--bgColor);
}

.tabs {
  margin-top: 0 !important;
  padding: 5px 1rem !important;
  background: var(--bgColor);
  flex: 0 1 100%;
}

#pageTabs {
  width: 100%;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 10;
  border-bottom: 2px solid var(--highlightColor);
  background: var(--bgColor);

  .tabs {
  }

  #miniEquity {

    #miniLogo {
      margin: ($smallGap * -1) 0;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      position: relative;
      background: #1d1d1d;
      overflow: hidden;
      white-space: nowrap;

      img {
        position: absolute;
        left: 6px;
        top: 6px;
        width: 18px;
      }
    }

    white-space: nowrap;
    overflow: hidden;
    transition: $transition;
    width: 0;
    display: flex;
    flex: 1 0 auto;
    align-items: center;
    gap: 10px;
    font-weight: 600;
    padding: $smallGap 0;
    background: var(--bgColor);
    border-bottom: 2px solid var(--notficationBorderColor);

    &.showMiniEquity {
      width: fit-content;
      padding: $smallGap;
      @media(max-width: 660px) {
      }
    }
  }
}

</style>
