<template>
  <div>
    <MBPButton :label="dictionary?.settingsPage ? dictionary.settingsPage.buttons.subscribeNow : 'Signup for subscription'" @click="openPayment()" :class="{mbp: !isSettings}" class="action bold"/>
  </div>
</template>

<script>
import MBPButton from "../../components/ui/controls/MBPButton";
import UserService from "../../services/user";
export default {
  name: "PaymentPartial",
  components: {MBPButton},
  props: ['user', 'isSettings'],
  data () {
    return {
      priceId: (window.location.href.includes("marketbit.pro")) ? 'pri_01hm9epda4wrh9w5d4wnyvwbmh' : 'pri_01hj0n3pffgvtj89tgwt383sc2'
    }
  },
  mounted() {
    if (!document.getElementById('paddleJS')) {
      const pURL = 'https://cdn.paddle.com/paddle/v2/paddle.js';
      const script = document.createElement('script');
      script.id = 'paddleJS';

      script.src = pURL;
      script.async = true;
      document.head.appendChild(script);

      script.onload = () => {
        this.setPaddlePayment()
      }
    } else {
      this.setPaddlePayment();
    }
  },
  methods: {
    async paymentSuccess(data) {

      try {
        await UserService.createSubscription(data.data.transaction_id);
        location.reload();
      } catch (e) {
      }
    },
    openPayment() {
      Paddle.Checkout.open({
        customer: {
          email: this.user.email,
        },
        settings: {
          theme: "light",
          allowLogout: false,
          showAddDiscounts: false
        },
        items: [
          {
            priceId: this.priceId,
            quantity: 1
          }
        ]
      });
    },
    setPaddlePayment() {
      if (!window.location.href.includes("marketbit.pro")) Paddle.Environment.set("sandbox");
      Paddle.Setup({
        eventCallback: (data) => {
          if (data.name == "checkout.completed") {
            this.paymentSuccess(data);
          }
        },
        token: process.env.VUE_APP_PADDLE_TOKEN// replace with a client-side token
      });
    }
  }
}
</script>

<style scoped>

</style>
