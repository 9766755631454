<template>
  <div id="searchPanel" ref="searchResults">

    <div id="top" ref="top">
      <div id="searchWrapper" :class="{asPage: asPage}" :dir="uiDirection">
        <i class="ri-search-2-line"/>
        <input type="search"
               ref="searchInput"
               :class="{autoWidth: autoWidth}"
               :placeholder="dictionary.symbolSearch.inputLabel" v-model="searchQuery" @keyup="keyup"
               @focus="showResults = true"/>

      </div>
      <div id="searchNote" style="    margin: 0 0 0;">
        <chart-note :note="dictionary.symbolSearch.inputLabelNote"/>
      </div>
    </div>

    <div id="searchResults" ref="tables" :class="{autoWidth: autoWidth, asPage: asPage, overflow}">
      <div v-if="searchQuery && searchQuery.trim().length > 0 && !isLoading && showResults">

        <div class="searchResult" v-if="results && results.length === 0">
          {{ dictionary.symbolSearch.noResults }} <span style="font-weight: 500">{{ searchQuery }}</span>
        </div>

        <table cellspacing="0" cellpadding="0" class="resultsTable" v-else>
          <thead>
          <tr>
            <th></th>
            <th class="center">{{ dictionary.symbolPage.price }}</th>
            <th class="center">{{ dictionary.symbolPage.pe }}</th>
            <th class="center">{{ dictionary.symbolPage.volume }}</th>
            <th class="center">{{ dictionary.symbolPage.marketCap }}</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(symbol, sIdx) in results">
            <td>
              <div class="equityWrapper">
                <symbol-logo :symbol="symbol.symbol"/>
                <div class="symbolLink">
                  <router-link :to="`/symbol/${symbol.symbol}`" @click="closeEvent()"/>
                  <div class="symbol">{{ symbol.symbol }}</div>
                  <div class="name" :dir="uiDirection">
                    <div style="unicode-bidi: plaintext; width: fit-content">{{ symbol.name }}</div>
                  </div>
                </div>
              </div>
            </td>

            <td class="center">
              <SymbolLabel
                  :pre-market-quote="symbol.preMarket"
                  :post-market-quote="symbol.postMarket"
                  :symbol="{symbol: symbol.symbol, price: symbol.price, changeP: symbol.changeP}"
                  :prices="{price: {price: symbol.price, changeP: symbol.changeP}}"
                  :always-on="true" :price-only="true"/>
            </td>
            <td class="center cell" :dir="uiDirection">{{ symbol.pe }}</td>
            <td class="center">{{ symbol.volume }}</td>
            <td class="center">{{ symbol.marketCap }}</td>

            <td class="center">
              <div @click="addRemoveWatchlistSymbol(symbol.symbol)" class="addRemoveResult">
                <div class="addRemoveSymbol add" v-if="!watchlist.watchlist.includes(symbol.symbol)">
                  {{ dictionary.buttons.addToPortfolio }}
                </div>
                <div class="addRemoveSymbol remove" v-else>{{ dictionary.buttons.removeFromPortfolio }}</div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <div v-if="withPreview && (!searchQuery || searchQuery.trim().length === 0)">
        <h3 style="margin: 0;">{{ dictionary.symbolSearch.frequentlySearched }}</h3>
        <table cellspacing="0" cellpadding="0" class="resultsTable">
          <thead>
          <tr>
            <th></th>
            <th></th>
            <th class="center">{{ dictionary.symbolPage.price }}</th>
            <th class="center">{{ dictionary.symbolPage.pe }}</th>
            <th class="center">{{ dictionary.symbolPage.eps }}</th>
            <th class="center">{{ dictionary.symbolPage.volume }}</th>
            <th class="center">{{ dictionary.symbolPage.marketCap }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(symbol, sIdx) in preview">
            <td>
              <div class="equityWrapper">
                <symbol-logo :symbol="symbol.symbol"/>
                <div class="symbolLink">
                  <symbol-link :symbol="symbol.symbol" :disable-preview="true" @click="closeEvent()"/>
<!--                  <router-link :to="`/symbol/${symbol.symbol}`" @click="closeEvent()"/>-->
                  <div class="symbol">{{ symbol.symbol }}</div>
                  <div class="name" :dir="uiDirection">
                    <div style="unicode-bidi: plaintext; width: fit-content">{{ symbol.name }}</div>
                  </div>
                </div>
              </div>
            </td>

            <td class="center">
              <div @click="addRemoveWatchlistSymbol(symbol.symbol)" class="addRemoveResult">
                <div class="addRemoveSymbol add" v-if="!watchlist.watchlist.includes(symbol.symbol)">
                  {{ dictionary.buttons.addToPortfolio }}
                </div>
                <div class="addRemoveSymbol remove" v-else>{{ dictionary.buttons.removeFromPortfolio }}</div>
              </div>
            </td>

            <td class="center">
              <SymbolLabel
                  :pre-market-quote="symbol.preMarket"
                  :post-market-quote="symbol.postMarket"
                  :symbol="{symbol: symbol.symbol, price: symbol.price, changeP: symbol.changeP}"
                  :prices="{price: {price: symbol.price, changeP: symbol.changeP}}"
                  :always-on="true" :price-only="true"/>
            </td>
            <td class="center cell" :dir="uiDirection">{{ symbol.pe }}</td>
            <td class="center cell" :dir="uiDirection">{{ symbol.eps ? '$' + symbol.eps : '-' }}</td>
            <td class="center">{{ symbol.volume }}</td>
            <td class="center">{{ symbol.marketCap }}</td>


          </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>
</template>

<script>

import SymbolsService from '../../services/symbols';
import UserService from '../../services/user';
import {useWatchlist} from "../../stores/watchlist";
import {onClickOutside} from '@vueuse/core'
import {getCurrentInstance, ref} from 'vue'

import eventBus from '../../services/events';
import mixpanel from 'mixpanel-browser';
import SymbolLabel from "../symbols/symbol";
import ChartNote from "../charts/ChartNote";
import SymbolLogo from "../symbols/symbolLogo";
import SymbolLink from "../symbols/symbolLink";

export default {
  name: "SymbolsSearch",
  components: {SymbolLink, SymbolLogo, ChartNote, SymbolLabel},
  props: ['asPage', 'big', 'overflow', 'withPreview', 'autoWidth'],
  data() {
    return {
      searchQuery: null,
      debounce: null,
      isLoading: false,
      showResults: false,
      watchlist: useWatchlist(),
      results: [],
      preview: []
    }
  },
  setup() {

    const searchResults = ref(null)
    const app = getCurrentInstance();
    onClickOutside(searchResults, (event) => {

      if (!app.ctx.asPage) app.data.showResults = false;
    })

    return {searchResults}
  },
  beforeUnmount() {
    eventBus.off('searchSymbolClick', this.searchSymbolClick)
  },
  beforeMount() {
    eventBus.on('searchSymbolClick', this.searchSymbolClick)
  },
  mounted() {
    if (this.withPreview) {
      this.loadPreview().then().catch()
    }

    this.$nextTick(() => {

      this.$refs.searchInput.focus();

      const navBar = document.querySelector('.searchContainer');
      const top = this.$refs.top;

      const heightOfDiv1 = navBar.clientHeight;
      const heightOfDiv3 = top.clientHeight;

      this.$refs.tables.style.height = `${heightOfDiv1 - heightOfDiv3 - 90}px`
    });
  },
  methods: {
    async loadPreview() {

      const preview = await SymbolsService.querySymbolsPreview();
      this.preview = preview.results;

    },
    closeEvent() {
      eventBus.emit('closeSearchPanel');
      this.results = [];
      this.searchQuery = null;
    },
    searchSymbolClick() {

      this.$refs.searchInput.focus();
      this.$refs.searchInput.classList.add('focus');
      setTimeout(() => {
        this.$refs.searchInput.classList.remove('focus');
      }, 12000)
    },
    async addRemoveWatchlistSymbol(symbol) {

      if (this.watchlist.watchlist.includes(symbol)) {

        try {
          mixpanel.track('symbol:remove', {client: window.location.host, symbol: symbol});
        } catch (e) {
        }


        const watchlist = await UserService.removeSymbolFromWatchlist(symbol);
        this.watchlist.updateWatchlist(watchlist);
      } else {

        try {
          mixpanel.track('symbol:add', {client: window.location.host, symbol: symbol});
        } catch (e) {
        }

        const watchlist = await UserService.addSymbolToWatchlist(symbol);
        this.watchlist.updateWatchlist(watchlist);
      }
      //
      // this.searchQuery = null;
      // this.results = [];
    },
    keyup() {

      if (this.searchQuery && this.searchQuery.toString().trim().length > 0) {

        this.isLoading = true;
        clearTimeout(this.debounce);
        this.debounce = setTimeout(async () => {

          try {
            mixpanel.track('symbol:search', {client: window.location.host, search: this.searchQuery})
          } catch (e) {

          }

          const results = await SymbolsService.querySymbols({query: this.searchQuery})
          this.results = results.results;
          this.isLoading = false;
        }, 300);
      }
    }
  }
}
</script>

<style scoped lang="scss">


@import "../../assets/style/general";

#searchWrapper {
  position: relative;
  z-index: 3;

  i {
    padding: 1rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  input {
    padding: 5px;
    border-radius: 50px;
    border: 2px solid var(--notficationBorderColor);
    width: 360px;
    transition: all .1s ease-in;

    &.autoWidth {
      width: auto;
    }
    @media(max-width: 660px) {
      width: 210px;
      margin-right: auto;
    }

    &:focus, &:active {
      border: 2px solid var(--highlightColor);
      background: var(--bgColor);
    }
  }
}

#searchWrapper.asPage {
  //padding: 1rem;
  margin-top: 1rem;
  margin-bottom: -.5rem;

  input {
    width: 100%;
    font-size: 16px;
  }
}

#searchWrapper.asPage[dir="ltr"] {
  //padding: 1rem;

  i {
    left: -5px !important;
  }

  input {
    width: 100%;
    font-size: 16px;
  }
}


#searchWrapper[dir="rtl"] {
  i {
    right: 10px;
  }

  input {
    padding-right: 30px;
  }
}

#searchWrapper[dir="rtl"].asPage {
  i {
    right: -5px;
  }
}

#searchWrapper[dir="ltr"] {
  i {
    left: 10px !important;
  }

  input {
    padding-left: 30px !important;

    &:focus,
    &:active {
      outline: none;
    }
  }
}

#searchWrapper[dir="ltr"].asPage {
  i {
    left: 25px;
  }
}

#searchPanel {
  position: relative;
  border-radius: 30px;
  z-index: 2;
  display: flex;
  flex-direction: column;

  .autoWidth {

  }

  @media (max-width: 660px) {
    position: unset;
  }
}

#searchResults {
  position: absolute;
  background: var(--bgColor);
  width: 120%;
  transform: translateY(5px);
  max-height: 150px;
  border: 1px solid var(--notficationBorderColor);
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  box-shadow: 0 5px 25px 0 rgba(15, 15, 15, .3), 0 5px 45px 0 rgba(15, 15, 15, .05);
  z-index: 9;

  &.autoWidth {
    width: 200%;
  }

  @media (max-width: 660px) {
    width: 100%;
    left: 0;
    max-height: 100vh;
    height: 100vh;
    box-shadow: none;
    overflow-x: auto;
    white-space: nowrap;
  }
}

#searchResults.asPage {
  position: unset;
  box-shadow: unset;
  border: unset;
  width: 100%;
  transform: unset;
  max-height: unset;
}

#searchResults.overflow {
  //flex: 1 0 68vh;
  overflow-y: auto;
}

.searchResult {
  align-items: center;
  padding: 5px 15px;
  border-bottom: 1px solid var(--notficationBorderColor);
  position: relative;

}

.name[dir="rtl"] {
  direction: rtl !important;
  text-align: right;
}

.focus {
  animation: shadow-animation 4s;
  animation-iteration-count: 4;
}


@keyframes shadow-animation {
  0% {
    box-shadow: 0 0 0px 0 var(--highlightColorOpacity);
  }


  50% {
    box-shadow: 0 0 20px 5px var(--highlightColorOpacity), 0 0 150px 10px rgba(0, 0, 0, 0.2), 0 0 500px 10px var(--highlightColorOpacity);
  }


  100% {
    box-shadow: 0 0 0 0 var(--highlightColorOpacity);
  }
}

.resultsTable {
  width: 100%;


  .symbolLink {
    width: 100%;
    position: relative;
  }

  .addRemoveResult {
    cursor: pointer;
    padding: 14px;
    margin: -14px;
    flex: 1 0 auto;
  }

  a {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: black;
  }


  .symbol {
    font-weight: 500;
  }

  .name {
  }

  .searchResultRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

  }

  tr {

    &:hover {
      background: var(--tableHoverColor);
    }

    &:last-of-type {
      border-bottom: none;
    }
  }


  th,
  td {
    padding: 5px;
    border-bottom: 1px solid var(--notficationBorderColor);
  }

  thead {
    tr {
      &:hover {
        background: var(--bgColor);
      }
    }
  }

  th {
    border-bottom: 2px solid var(--notficationBorderColor);
  }
}

.cell[dir="rtl"] {
  unicode-bidi: plaintext;
  direction: rtl;
}

.cell.period {
  width: fit-content;
}

.center {
  text-align: center;
}

.justify {
  justify-content: center;
  display: flex;
}

.equityWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}


</style>
