<template>
  <div id="heatmapWrapper">
    <div class="chart" ref="chart"></div>
  </div>
</template>

<script>

import PerformanceService from '../services/performance';
import * as echarts from "echarts";

export default {
  name: "HeatmapView",
  data() {
    return {
      sectors: null
    }
  },
  methods: {
    async getSectors() {

      this.sectors = await PerformanceService.getSectors();
    }
  },
  async mounted() {
    await this.getSectors();

    if (this.$refs.chart && this.sectors) {

      function getLevelOption() {
        return [
          {
            color: ['#942e38', '#aaa', '#269f3c'],
            colorMappingBy: 'changeP',
            itemStyle: {
              gapWidth: 1
            }
          }
        ];
      }

      let chart = echarts.init(this.$refs.chart);
      // chart.hideLoading();

      const visualMin = -5;
      const visualMax = 5;
      chart.setOption(
          {
            tooltip: {
              formatter: function (info) {
                if (info.data.children) {
                  return `<b>${info.data.name}</b>`
                }
                return `<b>${info.data.name} (${info.data.symbol})</b><br/>$${info.data.price} (${info.data.changeP}%)`;
              }
            },
            series: [
              {
                type: 'treemap',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                upperLabel: {
                  show: true,
                  height: 20
                },
                itemStyle: {
                  borderColor: '#fff'
                },
                visualMin: -20,
                visualMax: 20,
                visualDimension: 3,
                levels: [
                  {
                    itemStyle: {
                      borderWidth: 3,
                      borderColor: '#f4f4f4',
                      gapWidth: 3
                    }
                  },
                  {
                    color: ['#942e38', '#aaa', '#269f3c'],
                    colorMappingBy: 'value',
                    itemStyle: {
                      gapWidth: 1
                    }
                  }
                ],
                data: this.sectors
              }
            ]
          }
      );

    }
  }
}
</script>

<style scoped lang="scss">

.chart {
  height: 100vh;

}

</style>
