<template>
  <main>

    <big-loader v-if="isLoading"/>

    <div id="wrapper" ref="wrapper" v-else @scroll="scrolled">
      <p class="expl" ref="expl" v-if="!isPartial && !isMini">
        {{ dictionary.messages.trendingStocks }}
        <button @click="createFeed()" class="hideMobile">{{ dictionary.search.filter.seeNews }}</button>
      </p>

      <table style="width: 100%" cellpadding="0" cellspacing="0">
        <thead>
        <tr>
          <th style="cursor: pointer; user-select: none;" class="stickySymbol" :class="{xAxisScrolled}" :dir="uiDirection" @click="sortData('rank')">
            <div class="sortable">
              {{ dictionary.trendingStocks.symbol }}
              <TableSortTH :current-sort-direction="sortDir" :current-sort-value="sortBy" :show-sign="true"
                            sort-value="rank"/>
            </div>
          </th>
          <th class="center" @click="sortData('changeP')" style="cursor: pointer; user-select: none; width: 110px" v-if="!isMini"
              :class="{compareColumn: 1 === compareColumn}" @mouseover="columnHover(1)"
              @mouseleave="compareColumn = null">
            <div class="sortable">

              {{ dictionary.trendingStocks.price }}
              <TableSortTH :current-sort-direction="sortDir" :current-sort-value="sortBy" sort-value="changeP"
                            :show-sign="true"/>
            </div>
          </th>
          <th class="center" style="max-width: 160px;" v-if="!isMini" :class="{compareColumn: 2 === compareColumn}"
              @mouseover="columnHover(2)" @mouseleave="compareColumn = null">
            {{ dictionary.trendingStocks.sectorIndustry }}
          </th>

          <!--          <th style="width: 200px">{{dictionary.trendingStocks.name}}</th>-->
          <th></th>
          <th class="center" :class="{compareColumn: 4 === compareColumn}"
              style="cursor: pointer; user-select: none;"
              @mouseover="columnHover(4)"
              @click="sortData('mentions')"
              @mouseleave="compareColumn = null">
            <div class="sortable">

            {{ dictionary.trendingStocks.numberOfMentions }}
            <TableSortTH :current-sort-direction="sortDir" :current-sort-value="sortBy" sort-value="mentions"
                         :show-sign="true"/>
            </div>
          </th>
          <th class="center" :class="{sideBorder: !isMini, compareColumn: 5 === compareColumn}"
              @click="sortData('rankChange')"
              style="cursor: pointer; user-select: none;"
              @mouseover="columnHover(5)" @mouseleave="compareColumn = null" :dir="uiDirection">
            <div class="sortable">

            {{ dictionary.trendingStocks.ratingChange }}
            <TableSortTH :current-sort-direction="sortDir" :current-sort-value="sortBy" sort-value="rankChange"
                         :show-sign="true"/>
            </div>
          </th>
          <th v-if="!isMini" v-for="(period, pIdx) in periods" :class="{compareColumn: pIdx+6 === compareColumn}"
              @click="sortData(`eods.${period.value}`)"
              style="cursor: pointer; user-select: none;"
              @mouseover="columnHover(pIdx+6)" @mouseleave="compareColumn = null" class="center">
            <div class="sortable">
            {{ dictionary.trendingStocks.priceChange }} {{ period.label }}

            <TableSortTH :current-sort-direction="sortDir" :current-sort-value="sortBy" :sort-value="`eods.${period.value}`"
                         :show-sign="true"/>
            </div>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(trend, trendIdx) in list">
          <td class="relative stickySymbol" :class="{xAxisScrolled}" :dir="uiDirection">
<!--            <router-link :to="`/symbol/${trend.symbol}`" @click="$emit('close')"/>-->
            <symbol-link :symbol="trend.symbol" :disable-preview="isPartial" @click="$emit('close')"/>
            {{ trend.rank }}. {{ trend.symbol }}
          </td>
          <td style="width: 110px"
              :class="{noneComparedColumn: compareColumn !== null && compareColumn !== 1, compareColumn: 1 === compareColumn}"
              @mouseover="columnHover(1)">
            <symbol-label :symbol="trend" :price-only="true" :always-on="true" :prices="prices"/>
          </td>
          <td class="center" style="max-width: 160px;  overflow: hidden; text-overflow: ellipsis; white-space: pre;"
              v-if="!isMini"
              :class="{noneComparedColumn: compareColumn !== null && compareColumn !== 2, compareColumn: 2 === compareColumn}"
              @mouseover="columnHover(2)" @mouseleave="compareColumn = null">{{ formatSectorIndustry(trend) }}
          </td>

          <!--          <td class="companyName relative">-->
          <!--            <router-link :to="`/symbol/${trend.symbol}`"/>-->
          <!--            <div style="unicode-bidi: plaintext">{{trend.name}}</div>-->
          <!--          </td>-->
          <td v-if="!isMini">
            <div class="justify">
            <div class="addRemoveSymbol add fixedSixty" @click="addRemoveWatchlistSymbol(trend.symbol)"
                 v-if="!watchlist.watchlist.includes(trend.symbol)">
              {{ dictionary.buttons.addToPortfolioShort }}
            </div>
            <div class="addRemoveSymbol remove fixedSixty" @click="addRemoveWatchlistSymbol(trend.symbol)"
                 v-if="watchlist.watchlist.includes(trend.symbol)">
              {{ dictionary.buttons.removeFromPortfolioShort }}
            </div>
            </div>
          </td>
          <td :class="{noneComparedColumn: compareColumn !== null && compareColumn !== 4, compareColumn: 4 === compareColumn}"
              @mouseover="columnHover(4)" @mouseleave="compareColumn = null" class="center">{{ trend.mentions }}
          </td>
          <td @mouseover="columnHover(5)" @mouseleave="compareColumn = null" class="center rankingCell"
              :dir="uiDirection" :class="{compareColumn: 5 === compareColumn, sideBorder: !isMini}">
            <div
                :class="{red: setChangeColor(trend.rankChange) === 'red', green: setChangeColor(trend.rankChange) === 'green'}">
              {{ trend.rankChange }}
            </div>
          </td>
          <td v-if="!isMini"
              :class="{noneComparedColumn: compareColumn !== null && compareColumn !== 6+pIdx, red: setChangeColor(trend.eods[period.value]) === 'red', green: setChangeColor(trend.eods[period.value]) === 'green', compareColumn: 6+pIdx === compareColumn}"
              @mouseover="columnHover(6+pIdx)" @mouseleave="compareColumn = null" :dir="uiDirection"
              v-for="(period, pIdx) in periods" class="center priceChange">{{ trend.eods[period.value] }}%
          </td>
        </tr>
        </tbody>
      </table>

      <button @click="toTrendingStocks()" v-if="isPartial" id="seeFullList">
        {{ dictionary.trendingStocks.toTheFullList }}
      </button>

    </div>

    <div id="filterNews" ref="filterNews" v-if="!isLoading">
      <button @click="createFeed()" class="showMobile">{{ dictionary.search.filter.seeNews }}</button>
    </div>


    <!--    <div class="row calendarHeadersRow">-->
    <!--      <div class="cell min">{{dictionary.trendingStocks.symbol}}</div>-->
    <!--      <div class="cell center">{{dictionary.trendingStocks.name}}</div>-->
    <!--      <div class="cell center period" v-for="(period, pIdx) in periods">{{period.label}}</div>-->
    <!--      <div class="cell center">{{dictionary.trendingStocks.sectorIndustry}}</div>-->
    <!--      <div class="cell center min">{{dictionary.trendingStocks.ratingChange}}</div>-->
    <!--      <div class="cell center min">{{dictionary.trendingStocks.numberOfMentions}}</div>-->
    <!--    </div>-->
    <!--    <div v-for="(trend, trendIdx) in trends" class="row">-->
    <!--      <div class="cell min">-->
    <!--        <router-link :to="`/symbol/${trend.symbol}`"/>-->
    <!--        {{trend.rank}}. {{trend.symbol}} <symbol-label :symbol="trend" :price-only="true" :prices="prices"/></div>-->
    <!--      <div class="cell center companyName">-->
    <!--        <div>{{trend.name}}</div>-->
    <!--      </div>-->
    <!--      <div class="cell center period" :dir="uiDirection" v-for="(period, pIdx) in periods">{{trend.eods[period.value]}}</div>-->

    <!--      <div class="cell center">{{trend.sector}} - {{trend.industry}}</div>-->
    <!--      <div class="cell center rtl min" :dir="uiDirection" :style="setChangeColor(trend.rankChange)">{{trend.rankChange}}</div>-->
    <!--      <div class="cell center min">{{trend.mentions}}</div>-->
    <!--    </div>-->
  </main>
</template>

<script>
import NewsService from '../services/news';
import SocialTrend from '../services/socialBuzz';
import SymbolLabel from "../components/symbols/symbol";
import BigLoader from "../components/ui/BigLoader";
import UserService from "../services/user";
import {useWatchlist} from "../stores/watchlist";
import SortHoldings from "../components/sectors/SortHoldings";
import TableSortTH from "../components/ui/TableSortTH";
import SymbolLink from "../components/symbols/symbolLink";


import quoteStreaming from "../composables/quoteStreaming";

export default {
  name: "SocialTrend",
  props: ['prices', 'isPartial', 'isMini'],
  components: {SymbolLink, TableSortTH, SortHoldings, BigLoader, SymbolLabel},
  setup() {

    const { updateStream, leaveStream } = quoteStreaming();
    return {updateStream, leaveStream};
  },
  data() {
    return {
      sortDir: 1,
      sortBy: 'rank',
      compareColumn: null,
      xAxisScrolled: false,
      etf: {
        he: 'תעודת סל',
        en: 'ETF'
      },
      watchlist: useWatchlist(),
      isLoading: true,
      trends: []
    }
  },
  computed: {
    list() {

      return this.sortByKey(this.trends);
    },
    periods() {

      return ['5D', '1M', '6M'].map(period => {

        return this.dictionary.search.filter.periods.find(dPeriod => dPeriod.value === period)
      });
    }
  },
  methods: {
    sortByKey(array) {
      return array.sort((a, b) => {
        const keys = this.sortBy.split('.');
        let aValue = a;
        let bValue = b;

        for (const k of keys) {
          aValue = aValue[k];
          bValue = bValue[k];
        }

        const comparison = aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
        return comparison * this.sortDir;
      });
    },
    sortData(key) {

      if (this.sortBy === key) {
        this.sortDir *= -1;
      } else {
        this.sortBy = key;
        this.sortDir = 1
      }
    },
    toTrendingStocks() {
      this.$router.push('/social');
      this.$router.go(1);
      this.$emit('close');
    },
    columnHover(cIdx) {
      this.compareColumn = cIdx;
    },
    scrolled(ev) {

      const scrollLeftPosition = ev.target.scrollLeft;
      this.xAxisScrolled = Math.abs(scrollLeftPosition) > 10;
    },
    formatSectorIndustry(symbol) {

      if (symbol.name.includes('ETF')) return this.etf[this.systemLanguage];
      if (symbol.sector !== '-' && symbol.industry === '-') return `${symbol.sector}`
      if (symbol.sector === '-' && symbol.industry === '-') return `-`
      return symbol.industry;
      return `${symbol.sector} - ${symbol.industry}`
    },
    async createFeed() {

      this.isLoading = true;
      const feedId = await NewsService.createFilteredFeed({type: 'trending'});
      this.$router.push(`/feed/filtered/${feedId.feedId}`);
      this.$router.go(1);

    },
    setChangeColor(change) {
      return change > 0 ? 'green' : change < 0 ? 'red' : 'black';
    },
    async addRemoveWatchlistSymbol(symbol) {

      if (this.watchlist.watchlist.includes(symbol)) {

        try {
          mixpanel.track('symbol:remove', {client: window.location.host, symbol: symbol});
        } catch (e) {
        }

        const watchlist = await UserService.removeSymbolFromWatchlist(symbol)
        this.watchlist.updateWatchlist(watchlist);
      } else {

        try {
          mixpanel.track('symbol:add', {client: window.location.host, symbol: symbol});
        } catch (e) {
        }
        const watchlist = await UserService.addSymbolToWatchlist(symbol)
        this.watchlist.updateWatchlist(watchlist);
      }
    },
    async loadSocialTrends() {

      this.leaveStream('social');
      this.isLoading = true;
      this.trends = (await SocialTrend.getSocialTrend()).splice(0, this.isMini ? 20 : 100);
      if (this.isPartial) this.trends = this.trends.splice(0, 10);
      this.isLoading = false;

      this.updateStream(this.trends.map(trend => trend.symbol), 'social');


      this.$nextTick(() => {

        // if (window.innerWidth <= 660) {

        const navBar = document.querySelector('#navBar');
        const explP = this.$refs.expl;
        const filterNews = this.$refs.filterNews;

        const heightOfDiv1 = navBar.clientHeight;
        const heightOfDiv2 = 0 //explP.clientHeight;
        const heightOfDiv3 = window.innerWidth <= 660 ? filterNews.clientHeight : 5;

        const maxHeightDifference = heightOfDiv1 + heightOfDiv2 + heightOfDiv3;
        this.$refs.wrapper.style.height = `${window.innerHeight - maxHeightDifference - 5}px`
        // }
      });
    }
  },
  mounted() {

  },
  beforeUnmount() {
    this.leaveStream('social');
  },
  async beforeMount() {
    await this.loadSocialTrends();
  }
}
</script>

<style scoped lang="scss">
@import "../assets/style/calendar";
@import "../assets/style/vars";


.calendarHeadersRow {
  top: 0;
}

.cell {
  width: 20%;
  position: relative;
  white-space: pre;
  flex-shrink: 0;
  font-size: 14px;

  &.min {
    width: 10%;
  }

  a {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}

#wrapper {
  //@media(max-width: 660px) {
  overflow: auto;
  white-space: nowrap;
  //}
}

th, td {
  box-sizing: content-box;
  padding: 10px;
  white-space: nowrap;
}

thead {
  position: sticky;
  top: 0;
  z-index: 10;
  background: var(--bgColor);
}

th {
  border-bottom: 2px solid var(--notficationBorderColor);
}

td {
  border-bottom: 1px solid var(--notficationBorderColor);
}


tr {
  &:hover {
    background: var(--tableHoverColor);

    td.stickySymbol {
      background: var(--tableHoverColor);
      z-index: 1;
    }

    td:not(.green), td:not(.red) {
    }
  }
}

thead {
  tr {
    &:hover {
      background: var(--bgColor) !important;
    }
  }
}


.relative {
  position: relative;

  a {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.companyName {
  width: fit-content;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  div {
    overflow: hidden;
    white-space: nowrap;
    width: 200px;
    text-overflow: ellipsis;
  }
}

.cell[dir="rtl"] {
  unicode-bidi: plaintext;
  direction: rtl;
}

.cell.period {
  width: fit-content;
}

.center {
  text-align: center;
}

.justify {
  justify-content: center;
  display: flex;
}


td, th {
  &.compareColumn {
    background: var(--tableHoverColor) !important;
    font-weight: 600 !important;
  }

  &.noneComparedColumn {
    opacity: .75;
  }
}

td {

  &:hover {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.03);
    }
  }
}

.green {
  background: var(--opacityGreen);
  color: var(--green);
  text-align: center;
  unicode-bidi: plaintext;
  z-index: 1;
}

.red {
  background: var(--opacityRed);
  color: var(--red);
  text-align: center;
  unicode-bidi: plaintext;
  z-index: 1;
}


.priceChange.green, .priceChange.red {
  background: var(--bgColor);
}

#filterNews {
  padding: 1rem 0;
  display: flex;
  justify-content: center;
  @media(min-width: 661px) {
    display: none;
  }
}

button {
  border: 0;
  border-radius: 8px;
  font-size: 14px;
  background: var(--highlightColor);
  color: var(--bgColor);
  cursor: pointer;
  padding: 5px 23px;
}

#seeFullList {
  font-size: 18px;
  display: block;
  margin: 3rem auto;
}

.expl {
  white-space: normal;
  position: sticky;
  left: 5px;
}

.sideBorder {
  border-right: 1px solid var(--tableBorderColor);

  &[dir="rtl"] {
    border-right: unset;
    border-left: 1px solid var(--tableBorderColor);
  }
}

.stickySymbol {
  background: var(--bgColor);
  position: sticky;
  left: 0;
  border-right: 1px solid var(--tableBorderColor);
  z-index: 1;

  &[dir="rtl"] {
    border-right: unset;
    border-left: 1px solid var(--tableBorderColor);
    left: unset;
    right: 0;
  }
}

.xAxisScrolled {
  box-shadow: 3px 0 2px 0 rgba(0, 0, 0, 0.05);
  border-right: 1px solid var(--notficationBorderColor);

  &[dir="rtl"] {
    border-right: unset;
    border-left: 1px solid var(--notficationBorderColor);
    box-shadow: -3px 0 2px 0 rgba(0, 0, 0, 0.05);
  }
}

.rankingCell {
  position: relative;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}

.addRemoveSymbol {
  z-index: 1;
}

th {
  box-sizing: border-box;
  .sortable {
    display: inline-flex;
    justify-content: space-between;
    cursor: pointer;
    gap: 10px;
  }
}


</style>
