<template>
  <main v-if="news">

    <h1 style="direction: ltr">{{ news.title }}</h1>

    <section id="article" v-html="news.content"/>
  </main>
</template>

<script>

import NewsService from '../services/news';

export default {
  name: "ReadView",
  data() {
    return {
      news: null,
    }
  },
  methods: {
    async getNewsArticle() {

      const link = this.$route.params.link;
      this.news = await NewsService.readNews({link});
    }
  },
  beforeMount() {
    this.getNewsArticle()
  }
}
</script>

<style scoped lang="scss">

#article {
  padding: 1rem;
  direction: ltr !important;


  * {
    direction: ltr !important;
  }

  * + * {
    direction: ltr !important;
  }

  font-size: 18px;
  text-align: left;
  line-height: 32px;
}



html[data-theme='dark'] {
  #article:deep {
    p {
      img {
        max-width: 100%;
      }

      table {
        width: 100%;
      }
      a {
        color: var(--lightYellow) !important;
      }
    }
  }
}

h1 {
  font-size: 24px;
  padding: 1rem;
  line-height: 42px;
  margin-bottom: 0 0 -30px;
}
</style>
