<template>
  <div class="notification" :class="{rowNotification: isRow, newsNotification: type === 'news'}">

    <div class="events">
    </div>

    <div class="symbols"
         v-if="notification.symbols.length > 0 && !isRow && (type !== 'news' || notification.tags.includes('price target'))">
      <span class="classTag" v-if="notification.sourceType === 'pr' && notification.sourceName !== 'SEC'">
        <i class="ri-megaphone-line"/>  {{ dictionary.tags.pressRelease }}
        </span>

      <span class="classTag" v-if="notification.tags.includes('insiders') && notification.tags.includes('form-4')">
        <router-link :to="`/symbol/${notification.symbols[0].symbol}?tab=insiders`"/>
        <i class="ri-briefcase-4-line"/>  {{ dictionary.tags.insiders }}
        </span>

      <div v-for="(symbol, sIdx) in symbols.slice(0, !showMore ? 3 : notification.symbols.length)" :class="{inWatchlist: symbol.inWatchlist}">
        <SymbolLabel :symbol="symbol" :pre-market-quote="prices?.preMarketPrices?.[symbol.symbol]" :post-market-quote="prices?.postMarketPrices?.[symbol.symbol]" :prices="prices"/>
      </div>
      <div v-if="notification.symbols.length > 3" class="moreSymbols" @click="showMore = !showMore">

        <span v-if="!showMore">
          <i class="ri-eye-line"/> {{ notification.symbols.length - 3 }} {{ dictionary.tags.moreSymbols }}
        </span>
        <span v-else>
          <i class="ri-eye-off-line"/>
        {{ dictionary.tags.hideSymbols }}
        </span>

      </div>
    </div>

    <div class="notificationMessage" :class="{breakingNews: notification.isBreaking || notification.isbreaking || notification.tags.includes('highlight')}">

      <a :href="notification.link"
         v-if="!notification.isInner && notification.link && notification.link.includes('http')" target="_blank"/>
      <router-link v-else-if="notification.isInner" :to="`/read${notification.link}`"/>
      <p :style="shouldMarginBottom()" :class="{textRTL: notificationLang === 'he'}">
        {{ text }}
      </p>
    </div>

    <NotificationDetails @translate="originalLanguage = !originalLanguage" :feed-type="type"
                         :originalLanguage="originalLanguage"
                         :isRow="isRow" :notification="notification"/>

    <div v-if="notification.metaData && notification.metaData.bullets && notification.tags.includes('form-4')"
         class="bullets">

      <div class="shouldKnow" @click="showShouldKnow = !showShouldKnow; toggleShouldKnow()">

        <div>
          <i class="ri-lightbulb-line"/>
          {{ dictionary.tags.shouldKnowNotificationTitle }} (BETA)
        </div>

        <div class="shouldKnowToggle" v-if="!showShouldKnow">
          {{ dictionary.buttons.showMoreInfo }}
          <i class="ri-arrow-down-s-line"/>
        </div>

        <div class="shouldKnowToggle" v-if="showShouldKnow">
          {{ dictionary.buttons.hideMoreInfo }}
          <i class="ri-arrow-up-s-line"/>
        </div>
      </div>

      <div class="shouldKnowWrapper" ref="shouldKnowWrapper">
        <div class="bullet" v-for="(bullet, bIdx) in notification.metaData.bullets">
          <span style="font-size: 20px; margin-top: -4px;">&bull;</span> {{ bullet?.text[systemLanguage] }}
          <!--        <i class="ri-check-line"/> {{bullet?.text[systemLanguage]}}-->
        </div>
      </div>
    </div>

    <div class="notificationTags" v-if="notification.events.length > 0 && !this.isRow && this.type !== 'news'">

      <div v-for="(symbol, sIdx) in events">
        <div class="eventRow">

          <div v-for="(event, eIndx) in symbol.events" class="tag event"
               :class="{past: event.when && event.when === 'yesterday'}">
            <i class="ri-calendar-line"/>
            {{ notification.symbols.length > 1 ? symbol.symbol : '' }} {{ event.label }}{{ extraEventData(event) }}
            <div v-if="event.dividendRate" class="eventInfo">
              <i class="ri-question-mark" @click="divToolTipOn = !divToolTipOn"/>
              <div class="tooltipExplanation" v-if="divToolTipOn">

                <i class="ri-close-line" @click="divToolTipOn = false"/>
                {{ dictionary.messages.exDiv }}
              </div>
            </div>
          </div>
        </div>
      </div>


      <div v-if="events.length > 2" class="moreSymbols" @click="showMoreEvents = !showMoreEvents">

        <span v-if="!showMoreEvents">
          <i class="ri-eye-line"/> {{ events.length - 2 }} {{ dictionary.tags.moreEvents }}
        </span>
        <span v-else>
          <i class="ri-eye-off-line"/>
        {{ dictionary.tags.hideSymbols }}
        </span>
      </div>

    </div>


  </div>
</template>

<script>

import {useEquityPrices} from "../../../stores/equityPrices";
import SymbolLabel from "../../symbols/symbol";
import NotificationDetails from "./NotificationDetails";


import extraEventData from "../../../composables/eventTagExtraData";

export default {
  name: "Notification",
  components: {NotificationDetails, SymbolLabel},
  props: ['notification', 'isRow', 'type', 'hideEventsBySymbols'],
  setup() {

    const prices = useEquityPrices();
    return {prices, extraEventData};
  },
  data() {
    return {
      showShouldKnow: false,
      notificationLang: this.systemLanguage,
      divToolTipOn: false,
      originalLanguage: false,
      showMore: false,
      showMoreEvents: false
    }
  },
  computed: {
    symbols() {

      try {
        const watchlist = this.globalWatchlist.watchlist;
        let inWatchlistSymbol = this.notification.symbols.filter(symbol => watchlist.includes(symbol.symbol));
        let notInWatchlistSymbol = this.notification.symbols.filter(symbol => !watchlist.includes(symbol.symbol));

        if (notInWatchlistSymbol.length > 0) {

          inWatchlistSymbol = inWatchlistSymbol.map(symbol => {

            symbol.inWatchlist = true;
            return symbol;
          });
        }
        return [...inWatchlistSymbol, ...notInWatchlistSymbol];
      } catch (e) {
        return this.notification.symbols;
      }
    },
    events() {

      if (this.hideEventsBySymbols) {

        this.notification.events = this.notification.events.filter(event => !this.hideEventsBySymbols.includes(event.symbol))
      }
      return this.notification.events.slice(0, this.showMoreEvents ? this.notification.events.length : 2);
    },
    text() {

      if (!this.originalLanguage) {

        let notificationLanguage = this.systemLanguage === 'en' ? 'en' : 'he';
        this.notificationLang = notificationLanguage;
        // if (this.systemLanguage === 'en') return this.notification.title;
        return this.notification.languages && this.notification.languages[notificationLanguage] ? this.notification.languages[notificationLanguage] : this.notification.title;
      } else {

        let notificationLanguage = this.systemLanguage === 'en' ? 'he' : 'en';
        this.notificationLang = notificationLanguage;
        return this.notification.languages && this.notification.languages[notificationLanguage] ? this.notification.languages[notificationLanguage] : this.notification.title;
      }

      // if (this.systemLanguage === 'en') {
      //   return this.originalLanguage ?  this.notification.title;
      // } else {
      // }
      // if (this.originalLanguage) return this.notification.title;
      // return this.notification.languages && this.notification.languages[this.systemLanguage] ? this.notification.languages[this.systemLanguage] : this.notification.languages?.en || this.notification.title;
    }
  },
  methods: {
    toggleShouldKnow() {
      if (this.showShouldKnow) {
        this.$refs.shouldKnowWrapper.style.height = `${this.$refs.shouldKnowWrapper.scrollHeight}px`
      } else {
        this.$refs.shouldKnowWrapper.style.height = '0px';
      }
    },
    shouldMarginBottom() {
      return this.isRow ? {marginBottom: 0} : {}
    },
  }
}
</script>

<style scoped lang="scss">

@import "../../../assets/style/vars";
@import "../../../assets/style/notification";

.textRTL {
  direction: rtl;
  //unicode-bidi: plaintext;
  text-align: right;
}

.bullets {
  margin-top: 20px;

  .shouldKnowWrapper {
    overflow: hidden;
    height: 0;
    transition: $toggleTransition;
  }

  .shouldKnow {
    cursor: pointer;
    background: var(--lightYellowOpacity);
    color: var(--lightYellow);
    padding: 5px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .shouldKnowToggle {
      i {
        font-size: 22px;
      }

      font-size: 12px;
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }

  .bullet {

    i {
      margin-top: 2px;
    }

    display: flex;
    align-items: flex-start;
    gap: 5px;
    padding: 5px 0;
    font-size: 14px;
  }
}


</style>
