<template>
  <section>

    <div id="dividendFilter" class="hSpaceBetween">

      <div class="row">
        <div style="position: relative">
          <MBPButton class="small transparent" icon="ri-filter-3-line" @click="toggleFilterPanel"
                     :label="dictionary.buttons.filterStocks"/>
          <div class="filter" v-if="showFilterPanel" :dir="uiDirection">
            <transition name="fade" mode="out-in">

              <keep-alive>
                <FilterPanel
                    v-if="showFilterPanel"
                    @close="showFilterPanel = false"
                    @applyFilter="applyFilter"
                    @clearFilter="clearAllFilters"
                    @clear="clearFilter"
                    @select="filterSelected"
                    :title="titles[systemLanguage]"
                    :filters="filters"/>
              </keep-alive>
            </transition>
          </div>
        </div>
        <div style="position: relative">
          <MBPButton class="small transparent" @click="showColEdit = !showColEdit; showFilterPanel = false"
                     icon="ri-layout-column-line" :label="dictionary.buttons.editColumns"/>

          <div class="editColumns" v-if="showColEdit">
            <transition name="fade" mode="out-in">

              <keep-alive>
                <Panel v-if="showColEdit" :title="dictionary.buttons.editColumns" @close="showColEdit = false">
                  <div v-for="(th, thIndex) in header" style="margin-bottom: .3rem;" :key="`c-${th.key}`">
                    <MBPCheckbox
                        :key="`col-${th.key}`"
                        :name="`col-${th.key}`"
                        :default-checked="th.showCol"
                        @change="colDisplayToggle($event, thIndex)"
                        :labelId="`col-${th.key}`" :label="th.label"
                    />
                  </div>
                </Panel>
              </keep-alive>
            </transition>
          </div>
        </div>
      </div>

      <MBPSymbolSearch :mobile-popup="true" v-model="symbolsQuery" @input="searchSymbols"/>


    </div>

    <DataGridTable
        @scrollTopProgress="scrollTopPosition"
        :data="dividends"
        :hovered-columns="true"
        :class="{fadeOutTable: showColEdit || showFilterPanel}"
        key="divTable"
        :default-sort-direction="sortDirection"
        :dontSort="true"
        :default-sort="sortKey"
        :height-elements="['dividendFilter']"
        :fixed-col="0"
    >
      <template #thead="{thClick}">
        <HeadCell v-for="(th, thIndex) in header"
                  v-show="th.showCol"
                  :sortKey="sortKey"
                  :blockSort="true"
                  :key="`th-${thIndex}`"
                  :center="thIndex > 0"
                  :width="th.width"
                  :sortDirection="sortDirection"
                  :sortValue="th.sortKey"
                  @sort="changeSort(th.sortKey)">
          {{ th.label }}
        </HeadCell>
      </template>
      <template #tbody="{rows}">
        <tr v-for="(row, rowIndex) in rows"
            :key="`row-${rowIndex}`">
          <td v-show="header[0].showCol">
            <div class="symbolTd">
              <symbol-link :symbol="row.symbol"/>
              <symbol-logo :small="true" :symbol="row.symbol"/>
              {{ row.symbol }}
            </div>
          </td>
          <td class="" v-show="header[1].showCol">
            <div class="textOverflow" style="width: 170px;">
              <Popper
                  arrow
                  :hover="true"
                  v-if="formatName(row.name).tooLong"
                  :content="row.name"
              >
                {{ formatName(row.name).value }}
              </Popper>
              <text v-else>{{ row.name }}</text>
            </div>
          </td>
          <td class="" v-show="header[2].showCol">{{ row.payoutFrequency }}</td>
          <td class="" v-show="header[3].showCol">{{ row.yieldFormatted }}</td>
          <td class="" v-show="header[4].showCol">{{ row.payoutRatioFormatted }}</td>
          <td class="" v-show="header[5].showCol">{{ row.consecutiveYears }}</td>
          <td class="bidi" v-show="header[6].showCol">{{ row.consecutiveYearsGrowth }}</td>
          <td class="" v-show="header[7].showCol">{{
              row.nextDividend.dividend ? '$' + row.nextDividend?.dividend : '-'
            }}
          </td>
          <td class="" v-show="header[8].showCol">
            {{ row.nextDividend.payoutDateFormatted ? row.nextDividend?.payoutDateFormatted : '-' }}
          </td>
          <td class="" :class="{cellHighlight: row.exDateIsNear}" v-show="header[9].showCol">
            {{ row.nextDividend.exDateFormatted ? row.nextDividend?.exDateFormatted : '-' }}
          </td>
          <td class="" v-show="header[10].showCol">{{
              row.lastDividend.dividend ? '$' + row.lastDividend?.dividend : '-'
            }}
          </td>
          <td class="" v-show="header[11].showCol">
            {{ row.lastDividend.payoutDateFormatted ? row.lastDividend?.payoutDateFormatted : '-' }}
          </td>
          <td class="" v-show="header[12].showCol">
            {{ row.lastDividend.exDateFormatted ? row.lastDividend?.exDateFormatted : '-' }}
          </td>
          <td class=" bidi trendTd"
              v-show="header[13].showCol"
              :class="{red: row.oneYearGrowth && row.oneYearGrowth < 0, green: row.oneYearGrowth && row.oneYearGrowth > 0}">
            {{ row.oneYearGrowthFormatted ? row.oneYearGrowthFormatted : '-' }}
          </td>
          <td class=" bidi trendTd"
              v-show="header[14].showCol"
              :class="{red: row.threeYearGrowth && row.threeYearGrowth < 0, green: row.threeYearGrowth && row.threeYearGrowth > 0}">
            {{ row.threeYearGrowthFormatted ? row.threeYearGrowthFormatted : '-' }}
          </td>
          <td class=" bidi trendTd"
              v-show="header[15].showCol"
              :class="{red: row.fiveYearGrowth && row.fiveYearGrowth < 0, green: row.fiveYearGrowth && row.fiveYearGrowth > 0}">
            {{ row.fiveYearGrowthFormatted ? row.fiveYearGrowthFormatted : '-' }}
          </td>
          <td class="" v-show="header[16].showCol">{{ row.marketCapFormatted ? row.marketCapFormatted : '-' }}</td>
          <td class="" v-show="header[17].showCol">{{ row.industry ? row.industry : '-' }}</td>
          <td class="" v-show="header[18].showCol">{{ row.sector ? row.sector : '-' }}</td>
        </tr>
      </template>
    </DataGridTable>
  </section>
</template>

<script>
import Popper from "vue3-popper";

import DividendsService from '../services/dividends';
import DataGridTable from "../components/dataGrid/DataGridTable";
import HeadCell from "../components/dataGrid/HeadCell";
import SymbolLogo from "../components/symbols/symbolLogo";
import MBPButton from "../components/ui/controls/MBPButton";
import FilterPanel from "../components/filter/FilterPanel";
import Panel from "../components/ui/Panel";
import MBPCheckbox from "../components/ui/controls/MBPCheckbox";
import MBPSymbolSearch from "../components/ui/controls/MBPSymbolSearch";
import SymbolLink from "../components/symbols/symbolLink";

export default {
  name: "DividendsView",
  components: {
    SymbolLink,
    MBPSymbolSearch,
    MBPCheckbox,
    Panel,
    FilterPanel,
    MBPButton,
    SymbolLogo,
    HeadCell,
    DataGridTable,
    Popper
  },
  data() {
    return {
      symbolsQuery: null,
      isLoading: true,
      isScrollLoading: false,
      hasNextPage: true,
      showFilterPanel: false,
      showColEdit: false,
      totalPages: 0,
      total: 0,
      page: 0,
      sortKey: 'consecutiveYears',
      sortDirection: -1,
      query: {},
      filters: [
        {
          component: 'SectorFilter',
          fullWidth: true,
          label: this.dictionary.filters.sectors,
          multi: true,
          clear: true,
          key: 'sectors'
        },
        {
          component: 'IndustryFilter',
          fullWidth: true,
          autocomplete: true,
          label: this.dictionary.filters.industries,
          multi: true,
          clear: true,
          key: 'industries'
        },
        {
          component: 'MarketCapsFilter',
          fullWidth: true,
          label: this.dictionary.filters.marketCap,
          multi: true,
          clear: true,
          key: 'marketCap'
        },
        {
          component: 'DividendYieldFilter',
          fullWidth: true,
          clear: true,
          label: this.dictionary.filters.dividendYield,
          key: 'dividendYield'
        },
        {
          component: 'DividendFrequencyFilter',
          fullWidth: true,
          label: this.dictionary.filters.dividendFrequency,
          multi: true,
          clear: true,
          key: 'dividendFrequency'
        },
        {
          component: 'DividendConsecutiveYearsPayout',
          fullWidth: true,
          clear: true,
          label: this.dictionary.filters.dividendConsecutiveYears,
          key: 'consecutiveYearsDividend'
        },
        {
          component: 'DividendPayoutRatio',
          fullWidth: true,
          clear: true,
          label: this.dictionary.filters.dividendPayoutRatio,
          key: 'payoutRatio'
        }
      ],
      dividends: [],
      titles: {
        he: 'סינון מניות דיבידנד',
        en: 'Filter dividend stocks'
      },
      header: [{
        label: this.dictionary.dividends.symbol,
        sortKey: 'symbol',
        key: 'symbol',
        showCol: true
      }, {
        label: this.dictionary.dividends.name,
        key: 'name',
        width: 170,
        showCol: false
      }, {
        label: this.dictionary.dividends.payoutFrequency,
        key: 'payoutFrequency',
        width: 170,
        showCol: true
      }, {
        label: this.dictionary.dividends.yield,
        sortKey: 'yield',
        key: 'yield',
        showCol: true
      }, {
        label: this.dictionary.dividends.payoutRatio,
        sortKey: 'payoutRatio',
        key: 'payoutRatio',
        showCol: false
      }, {
        label: this.dictionary.dividends.consecutiveYears,
        sortKey: 'consecutiveYears',
        key: 'consecutiveYears',
        showCol: false
      }, {
        label: this.dictionary.dividends.consecutiveYearsGrowth,
        sortKey: 'consecutiveYearsGrowth',
        key: 'consecutiveYearsGrowth',
        showCol: false
      }, {
        label: this.dictionary.dividends.nextDivAmount,
        sortKey: 'nextDividend.dividend',
        key: 'nextDividend.dividend',
        showCol: true
      }, {
        label: this.dictionary.dividends.nextDivPayoutDate,
        sortKey: 'nextDividend.payoutDate',
        key: 'nextDividend.payoutDate',
        showCol: true
      }, {
        label: this.dictionary.dividends.nextDivExDate,
        sortKey: 'nextDividend.exDate',
        key: 'nextDividend.exDate',
        showCol: true
      }, {
        label: this.dictionary.dividends.lastDivAmount,
        sortKey: 'lastDividend.dividend',
        key: 'lastDividend.dividend',
        showCol: true
      }, {
        label: this.dictionary.dividends.lastDivPayoutDate,
        sortKey: 'lastDividend.payoutDate',
        key: 'lastDividend.payoutDate',
        showCol: false
      }, {
        label: this.dictionary.dividends.lastDivExDate,
        sortKey: 'lastDividend.exDate',
        key: 'lastDividend.exDate',
        showCol: false
      }, {
        label: this.dictionary.dividends.oneYearGrowth,
        sortKey: 'oneYearGrowth',
        key: 'oneYearGrowth',
        showCol: true
      }, {
        label: this.dictionary.dividends.threeYearGrowth,
        sortKey: 'threeYearGrowth',
        key: 'threeYearGrowth',
        showCol: true
      }, {
        label: this.dictionary.dividends.fiveYearGrowth,
        sortKey: 'fiveYearGrowth',
        key: 'fiveYearGrowth',
        showCol: true
      }, {
        label: this.dictionary.dividends.marketCap,
        sortKey: 'marketCap',
        key: 'marketCap',
        showCol: false
      }, {
        label: this.dictionary.dividends.industry,
        showCol: false,
        key: 'industry'
      }, {
        label: this.dictionary.dividends.sector,
        showCol: false,
        key: 'sector'
      }],
      symbolQueryTimeout: null
    }
  },
  methods: {
    async searchSymbols(symbols) {

      await this.clearAllFilters();
      if (!this.symbolsQuery && this.symbolsQuery.toString().trim().length === 0) {
        return;
      }
      clearTimeout(this.symbolQueryTimeout);

      this.symbolQueryTimeout = setTimeout(async () => {

        this.hasNextPage = true;
        this.totalPages = 0;
        this.total = 0;
        this.page = 0;

        await this.getDividendsData(true);
      }, 300);
    },
    formatName(name) {


      let tooLong = false;
      let value = name;

      if (name.length > 20) {

        value = name.substr(0, 20) + '...'
        tooLong = true;
      }

      return {tooLong, value}
    },
    async changeSort(key) {

      if (key === this.sortKey) {
        this.sortDirection *= -1;
      } else {
        this.sortKey = key;
        this.sortDirection = -1;
      }

      this.hasNextPage = true;
      this.totalPages = 0;
      this.total = 0;
      this.page = 0;

      await this.getDividendsData(true)
    },
    colDisplayToggle(checkbox, index) {

      this.header[index].showCol = checkbox.target.checked;
    },
    async applyFilter() {

      this.showFilterPanel = false;
      this.hasNextPage = true;
      this.totalPages = 0;
      this.total = 0;
      this.page = 0;

      await this.getDividendsData(true);
    },
    async clearAllFilters() {

      this.hasNextPage = true;
      this.totalPages = 0;
      this.total = 0;
      this.page = 0;
      this.query = {}

      await this.getDividendsData(true);
    },
    clearFilter(filterKey) {
      if (filterKey === 'sectors') {

        if (this.query.hasOwnProperty('sectors')) delete this.query.sectors;
      } else if (filterKey === 'industries') {

        if (this.query.hasOwnProperty('industries')) delete this.query.industries;
      } else if (filterKey === 'marketCap') {

        if (this.query.hasOwnProperty('marketCap')) delete this.query.marketCap;
      } else if (filterKey === 'dividendFrequency') {

        if (this.query.hasOwnProperty('dividendFrequency')) delete this.query.dividendFrequency;
      } else if (filterKey === 'dividendYield') {

        if (this.query.hasOwnProperty('dividendYield')) delete this.query.dividendYield;
      } else if (filterKey === 'consecutiveYearsDividend') {

        if (this.query.hasOwnProperty('consecutiveYearsDividend')) delete this.query.consecutiveYearsDividend;
      } else if (filterKey === 'payoutRatio') {

        if (this.query.hasOwnProperty('payoutRatio')) delete this.query.payoutRatio;
      }
    },
    filterSelected(selection) {

      if (selection.filter === 'sectors') {

        this.toggleArray({
          object: this.query,
          key: 'sectors',
          value: selection.data.value,
          deleteIfEmpty: true
        });
      } else if (selection.filter === 'industries') {
        this.toggleArray({
          object: this.query,
          key: 'industries',
          value: selection.data.value,
          deleteIfEmpty: true
        });
      } else if (selection.filter === 'marketCap') {
        this.toggleArray({
          object: this.query,
          key: 'marketCap',
          value: selection.data.value,
          deleteIfEmpty: true
        });
      } else if (selection.filter === 'dividendFrequency') {
        this.toggleArray({
          object: this.query,
          key: 'dividendFrequency',
          value: selection.data.value,
          deleteIfEmpty: true
        });
      } else if (selection.filter === 'dividendYield') {

        if (!this.query.dividendYield) this.query.dividendYield = selection.data.value;
      } else if (selection.filter === 'consecutiveYearsDividend') {

        if (!this.query.consecutiveYearsDividend) this.query.consecutiveYearsDividend = selection.data.value;
      } else if (selection.filter === 'payoutRatio') {

        if (!this.query.payoutRatio) this.query.payoutRatio = selection.data.value;
      }
    },
    toggleArray({object, key = null, value, deleteIfEmpty = false}) {

      if (!object[key]) {

        object[key] = [value];
      } else {

        const indexOf = object[key].indexOf(value);
        if (indexOf === -1) {

          object[key].push(value);
        } else {
          object[key].splice(indexOf, 1);
        }
      }

      if (deleteIfEmpty) {

        if (object[key] !== undefined && object[key].length === 0) delete object[key];
      }

    },
    constructFilterQuery(query) {

      let queryObject = {};
      for (let k in query) {

        queryObject[k] = Array.isArray(query[k]) ? query[k].join(',') : query[k];
      }

      if (this.symbolsQuery && this.symbolsQuery.toString().trim().length > 0) {

        queryObject.symbols = this.symbolsQuery;
      }

      return queryObject;
    },
    toggleFilterPanel() {
      this.showFilterPanel = !this.showFilterPanel;
      this.showColEdit = false;
    },
    scrollTopPosition(value) {

      if (value >= 0.8 && !this.isScrollLoading && this.hasNextPage) {

        this.isScrollLoading = true;
        this.getDividendsData().then().catch();
      }
    },
    async getDividendsData(resetCurrentList = false) {

      const filter = this.constructFilterQuery(this.query);
      const results = await DividendsService.getDividends(Object.assign(filter, {
        page: this.page,
        sortKey: this.sortKey,
        sortDirection: this.sortDirection
      }));
      this.dividends = resetCurrentList ? this.dividends = results.results : this.dividends.concat(results.results);
      this.hasNextPage = results.hasNextPage;
      this.totalPages = results.totalPages;
      this.page++;
      this.total = results.total;
      this.isScrollLoading = false;
    }
  },
  async mounted() {

    await this.getDividendsData();
  }
}
</script>

<style scoped lang="scss">

@import "../assets/style/general";

#dividendFilter {
  gap: 10px;
  display: inline-flex;
  padding: 10px;
  position: relative;
  box-sizing: border-box;
  width: 100%;

  .filter {
    box-shadow: 0 15px 10px 0 rgba(0, 0, 0, 0.25);
    width: 500px;
    transform: translateY(100%);
    z-index: 9999;
    position: absolute;
    bottom: 0;
    left: 0;

    &[dir="rtl"] {
      left: unset;
      right: 0;
    }

    @media(max-width: 660px) {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      bottom: unset;
      transform: unset;
    }
  }
}


.editColumns {
  position: absolute;
  width: 440px;
  max-height: 380px;
  z-index: 99;
  bottom: 0;
  transform: translateY(100%);
  @media(max-width: 660px) {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    bottom: unset;
    transform: unset;
  }
}
</style>
