<template>
  <main key="sectorsList">

    <big-loader v-if="isLoading"/>
    <section v-else>
      <!--    <div id="barchartWrapper" v-if="sectors && sectors.length > 0">-->

      <!--      <h3>{{dictionary.sectors.daily}}</h3>-->
      <!--    <Barchart-->
      <!--        v-if="sectors.length > 0"-->
      <!--        :no-zoom="true"-->
      <!--        :height="500"-->
      <!--        :positive-negative="true"-->
      <!--        :tool-tip-formatter="toolTipFormatter"-->
      <!--        :rotate-label="45"-->
      <!--        :y-axis-format="(value)=> (value + '%')"-->
      <!--        :labels="sectorLabels"-->
      <!--        :x-axis-labels="sectorLabels"-->
      <!--        :series="series"/>-->
      <!--    </div>-->

      <!--    <h3>{{dictionary.sectors.performance}}</h3>-->

      <p class="expl">
        {{ dictionary.messages.sectors }}
      </p>

      <div id="priceChangeTable">
        <PriceChange :symbol-col-width="'180px'"
                     @rowClick="rowClick"
                     :clickableSymbol="true"
                     :currently-loading="currentlyLoading"
                     :price-change="priceChanges" :display-key="'name'" v-if="priceChanges"/>
      </div>

      <div v-for="(sector, sectorIdx) in sectorsList">
        <OverlayUI v-if="showHoldingsForSymbol === sector.symbol">
          <div class="sector" @scroll="scrolled">
            <div class="sectorRow sectorHeader"
                 :class="{sectorOpen: holdings[sector.symbol] && showHoldingsForSymbol === sector.symbol}">
              <div @click="showHoldings(sector.symbol)" style="cursor: pointer;">
                <div class="companyName" :dir="uiDirection">
                  <SymbolLabel :showName="true" :changeOnly="true" :post-market-quote="sector.postMarket" :pre-market-quote="sector.preMarket" :alwaysOn="true" :dontLink="true" :symbol="sector"
                               :prices="{price: sector.price}"/>
                </div>
              </div>

              <div class="holdingsToolbar" :dir="uiDirection"
                   v-if="sortHoldings[sector.symbol] && holdings[sector.symbol] && showHoldingsForSymbol === sector.symbol">
                <div>
                  <div/>
                  <div class="symbolInfo">

                  </div>
                </div>
              </div>
              <div class="holdingsToolbar" v-else/>

              <div class="sectorTools" :dir="uiDirection">
                <small-loader v-if="holdingsLoading[sector.symbol]" :inline="true"/>
                <div class="toggleHoldings" @click="showHoldings(sector.symbol)" style="cursor: pointer">
                  <i class="ri-close-line" v-if="holdings[sector.symbol] && showHoldingsForSymbol === sector.symbol"/>
                  <i class="ri-arrow-down-s-line" v-else/>
                </div>
              </div>

            </div>

            <div class="holdings" :ref="`${sector.symbol}-holdings`"
                 v-if="holdings[sector.symbol] && showHoldingsForSymbol === sector.symbol">

              <table style="width: 100%" cellspacing="0" cellpadding="0" border="0">
                <thead>
                <tr>
                  <th  :class="{xAxisScrolled}" class="stickySymbol">{{dictionary.trendingStocks.symbol}}</th>
                  <th @click="setSort(sector.symbol, 'changeP')">
                    <div>
                      {{dictionary.trendingStocks.price}}
                      <SortHoldings
                          :current-sort-direction="sortHoldings[sector.symbol].dir"
                          :current-sort-value="sortHoldings[sector.symbol].value"
                          :sector-symbol="sector.symbol"
                          :sort-value="'changeP'"
                          @click="setSort(sector.symbol, 'changeP')"
                      />
                    </div>
                  </th>
                  <th @click="setSort(sector.symbol, 'periodicalChange.5D')">
                    <div>
                      {{dictionary.chart.periods['5d']}}
                      <SortHoldings
                          :current-sort-direction="sortHoldings[sector.symbol].dir"
                          :current-sort-value="sortHoldings[sector.symbol].value"
                          :sector-symbol="sector.symbol"
                          :sort-value="'periodicalChange.5D'"
                          @click="setSort(sector.symbol, 'periodicalChange.5D')"
                      />
                    </div>
                  </th>
                  <th @click="setSort(sector.symbol, 'periodicalChange.1M')">
                    <div>
                      {{dictionary.chart.periods['1m']}}
                      <SortHoldings
                          :current-sort-direction="sortHoldings[sector.symbol].dir"
                          :current-sort-value="sortHoldings[sector.symbol].value"
                          :sector-symbol="sector.symbol"
                          :sort-value="'periodicalChange.1M'"
                          @click="setSort(sector.symbol, 'periodicalChange.1M')"
                      />
                    </div>
                  </th>
                  <th @click="setSort(sector.symbol, 'periodicalChange.1Y')">
                    <div>
                      {{dictionary.chart.periods['1y']}}
                      <SortHoldings
                          :current-sort-direction="sortHoldings[sector.symbol].dir"
                          :current-sort-value="sortHoldings[sector.symbol].value"
                          :sector-symbol="sector.symbol"
                          :sort-value="'periodicalChange.1Y'"
                          @click="setSort(sector.symbol, 'periodicalChange.1Y')"
                      />
                    </div>
                  </th>
                  <th @click="setSort(sector.symbol, 'periodicalChange.3Y')">
                    <div>
                      {{dictionary.chart.periods['3y']}}
                      <SortHoldings
                          :current-sort-direction="sortHoldings[sector.symbol].dir"
                          :current-sort-value="sortHoldings[sector.symbol].value"
                          :sector-symbol="sector.symbol"
                          :sort-value="'periodicalChange.3Y'"
                          @click="setSort(sector.symbol, 'periodicalChange.3Y')"
                      />
                    </div>
                  </th>
                  <th @click="setSort(sector.symbol, 'weight')">
                    <div>
                      {{ dictionary.sectors.sectorRatio }}
                      <SortHoldings
                          :current-sort-direction="sortHoldings[sector.symbol].dir"
                          :current-sort-value="sortHoldings[sector.symbol].value"
                          :sector-symbol="sector.symbol"
                          :sort-value="'weight'"
                          @click="setSort(sector.symbol, 'weight')"
                      />
                    </div>
                  </th>
                  <th @click="setSort(sector.symbol, 'volume')">
                    <div>
                      {{ dictionary.sectors.volume }}
                      <SortHoldings
                          :current-sort-direction="sortHoldings[sector.symbol].dir"
                          :current-sort-value="sortHoldings[sector.symbol].value"
                          :sector-symbol="sector.symbol"
                          :sort-value="'volume'"
                          @click="setSort(sector.symbol, 'volume')"
                      />
                    </div>
                  </th>
                  <th @click="setSort(sector.symbol, 'marketCap')">
                    <div>
                      {{ dictionary.sectors.marketCap }}
                    <SortHoldings
                        :current-sort-direction="sortHoldings[sector.symbol].dir"
                        :current-sort-value="sortHoldings[sector.symbol].value"
                        :sector-symbol="sector.symbol"
                        :sort-value="'marketCap'"
                        @click="setSort(sector.symbol, 'marketCap')"
                    />
                    </div>
                  </th>
                  <th @click="setSort(sector.symbol, 'pe')">
                    <div>
                      {{ dictionary.sectors.pe }}
                      <SortHoldings
                          :current-sort-direction="sortHoldings[sector.symbol].dir"
                          :current-sort-value="sortHoldings[sector.symbol].value"
                          :sector-symbol="sector.symbol"
                          :sort-value="'pe'"
                          @click="setSort(sector.symbol, 'pe')"
                      />
                    </div>
                  </th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                  <tr v-for="(holding, holdingIdx) in holdingsList[sector.symbol]">
                    <td :class="{xAxisScrolled}" class="stickySymbol" style="cursor: pointer;" :dir="uiDirection">
                      <div style="position: relative">
                        <symbol-link :symbol="holding.symbol"/>
                      <b>{{holding.symbol}}</b><br/>
                      <span class="companyHoldingName">{{ holding.name }}</span>
                      </div>
                    </td>
                    <td>
                      <SymbolLabel :alwaysOn="true" :price-only="true" :key="`p-${holding.symbol}`" :symbol="holding" :prices="{price: holding.price}"/>
                    </td>

                    <td :dir="uiDirection">{{holding.periodicalChange?.['5D'] ? holding.periodicalChange['5D'] + '%' : '-'}}</td>
                    <td :dir="uiDirection">{{holding.periodicalChange?.['1M'] ? holding.periodicalChange['1M'] + '%' : '-'}}</td>
                    <td :dir="uiDirection">{{holding.periodicalChange?.['1Y'] ? holding.periodicalChange['1Y'] + '%' : '-'}}</td>
                    <td :dir="uiDirection">{{holding.periodicalChange?.['3Y'] ? holding.periodicalChange['3Y'] + '%' : '-'}}</td>
                    <td :dir="uiDirection">{{ holding.weight }}</td>
                    <td :dir="uiDirection">{{ holding.volume }}</td>
                    <td :dir="uiDirection">{{ holding.marketCap }}</td>
                    <td :dir="uiDirection">{{ holding.pe }}</td>
                    <td>
                      <div class="addRemoveSymbol add" v-if="!watchlist.watchlist.includes(holding.symbol)"
                           @click="addSymbolToWatchlist(holding.symbol)">{{ dictionary.buttons.addToPortfolio }}
                      </div>

                      <div class="addRemoveSymbol remove" @click="removeSymbolFromWatchlist(holding.symbol)" v-else>
                        {{ dictionary.buttons.removeFromPortfolio }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

<!--              <div v-for="(holding, holdingIdx) in holdingsList[sector.symbol]">-->
<!--                <div class="sectorRow sectorSymbol">-->
<!--                  <div>-->
<!--                    <div class="symbolName">-->
<!--                      <SymbolLabel :alwaysOn="true" :symbol="holding" :prices="{price: holding.price}"/>-->
<!--                      <div class="addRemoveSymbol add showMobile" v-if="!watchlist.watchlist.includes(holding.symbol)"-->
<!--                           @click="addSymbolToWatchlist(holding.symbol)">{{ dictionary.buttons.addToPortfolio }}-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="companyName" :dir="uiDirection">{{ holding.name }}</div>-->
<!--                  </div>-->

<!--                  <div class="symbolInfo">-->

<!--                    <div class="addRemoveSymbol add hideMobile" v-if="!watchlist.watchlist.includes(holding.symbol)"-->
<!--                         @click="addSymbolToWatchlist(holding.symbol)">{{ dictionary.buttons.addToPortfolio }}-->
<!--                    </div>-->

<!--                    <div class="addRemoveSymbol remove" @click="removeSymbolFromWatchlist(holding.symbol)" v-else>-->
<!--                      {{ dictionary.buttons.removeFromPortfolio }}-->
<!--                    </div>-->

<!--                    <div class="holdingStat" :dir="uiDirection">-->
<!--                      <label>{{ dictionary.sectors.sectorRatio }}</label>-->
<!--                      {{ holding.weight }}-->
<!--                    </div>-->

<!--                    <div class="holdingStat" :dir="uiDirection">-->
<!--                      <label>{{ dictionary.sectors.volume }}</label>-->
<!--                      {{ holding.volume }}-->
<!--                    </div>-->


<!--                    <div class="holdingStat" :dir="uiDirection">-->
<!--                      <label>{{ dictionary.sectors.marketCap }}</label>-->
<!--                      {{ holding.marketCap }}-->
<!--                    </div>-->
<!--                    <div class="holdingStat" :dir="uiDirection">-->
<!--                      <label>{{ dictionary.sectors.pe }}</label>-->
<!--                      {{ holding.pe }}-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
            </div>
          </div>
        </OverlayUI>

      </div>
    </section>

  </main>
</template>

<script>

import DailyBriefService from '../services/dailyBrief';
import SymbolLabel from "../components/symbols/symbol";
import SmallLoader from "../components/ui/SmallLoader";
import {useNewYorkTime} from "../stores/newYorkTime";

import useWatchlist from '../composables/addRemoveSymbolFromWatchlist';
import SortHoldings from "../components/sectors/SortHoldings";
import eventBus from "../services/events";
import Barchart from "../components/charts/BarChart";
import PriceChange from "./symbolView/PriceChange";
import OverlayUI from "../components/ui/Overlay";
import BigLoader from "../components/ui/BigLoader";
import SymbolLogo from "../components/symbols/symbolLogo";
import SymbolLink from "../components/symbols/symbolLink";
import quoteStreaming from "../composables/quoteStreaming";


export default {
  name: "SectorsView",
  components: {
    SymbolLink,
    SymbolLogo, BigLoader, OverlayUI, PriceChange, Barchart, SortHoldings, SmallLoader, SymbolLabel},
  data() {
    return {
      xAxisScrolled: false,
      priceChanges: null,
      isLoading: false,
      sectors: [],
      holdings: {},
      sortHoldings: {},
      refreshInterval: null,
      showHoldingsForSymbol: null,
      holdingsLoading: {},
      currentlyLoading: null,
      activeTab: null,
    }
  },
  setup() {
    const nyTime = useNewYorkTime();
    const {updateStream, leaveStream} = quoteStreaming();
    const {addSymbolToWatchlist, removeSymbolFromWatchlist, watchlist} = useWatchlist();

    return {nyTime, addSymbolToWatchlist, removeSymbolFromWatchlist, watchlist, updateStream, leaveStream}
  },
  computed: {
    series() {
      return [this.sectors.reverse().map(sector => (sector.changeP))]
    },
    sectorLabels() {

      return this.sectors.reverse().map(sector => (sector.name))
    },
    toolTipFormatter() {
      return (params) => {
        const dataIndex = params[0].dataIndex;
        return `
                <div style="color: black; unicode-bidi: plaintext; font-family: 'Assistant', sans-serif; min-width: 150px">
                <div style="text-align: center"><b>${params[0].name}</b><br/><span style="unicode-bidi: plaintext; direction: ltr">${this.sectorsList[dataIndex].changeP}%</span></div>
                </div>
                `;
      }
    },
    sectorsList() {
      return this.sectors.sort((a, b) => b.changeP - a.changeP);
    },
    sectorsListReverse() {
      return this.sectors.sort((a, b) => a.changeP - b.changeP);
    },
    holdingsList() {

      let sortedHoldings = {};

      for (let k in this.holdings) {

        let holdingsSortValue = this.sortHoldings[k]?.value;
        let holdingsSortDir = this.sortHoldings[k]?.dir;
        let sortKeyName = 'changeP';

        switch (holdingsSortValue) {

          case 'sectorRatio':

            sortKeyName = 'weightValue';

            break;
          case 'changeP':
            sortKeyName = 'changeP';
            break;

          case 'marketCap':
            sortKeyName = 'marketCapValue'
            break;

          case 'volume':
            sortKeyName = 'volumeValue';
            break;

          case 'pe':
            sortKeyName = 'pe';
            break;
        }

        sortedHoldings[k] = this.holdings[k].sort((a, b) => {

          if (!holdingsSortValue) {
            return b.changeP - a.changeP;
          } else {

            if (holdingsSortDir === 1) {

              if (sortKeyName.includes('periodicalChange')) {

                const period = sortKeyName.split('.')[1];
                return a.periodicalChange[period] - b.periodicalChange[period];
              }

              return a[sortKeyName] - b[sortKeyName];
            } else {


              if (sortKeyName.includes('periodicalChange')) {

                const period = sortKeyName.split('.')[1];
                return b.periodicalChange[period] - b.periodicalChange[period];
              }


              return b[sortKeyName] - a[sortKeyName];
            }
          }
        });
      }

      return sortedHoldings;
    }
  },
  methods: {
    rowClick(value) {

      this.showHoldings(value.symbol)
    },
    tabClick(tab) {

    },
    showSectorNews(symbol) {
      eventBus.emit('navigate', {type: 'feed', value: 'sector', symbol: symbol})
    },
    scrolled (ev) {

      const scrollLeftPosition = ev.target.scrollLeft;
      this.xAxisScrolled = Math.abs(scrollLeftPosition) > 10;
    },
    setSort(sectorSymbol, sortValue) {

      if (this.sortHoldings[sectorSymbol].value === sortValue) {
        this.sortHoldings[sectorSymbol].dir *= -1;
      } else {

        this.sortHoldings[sectorSymbol].value = sortValue;
        this.sortHoldings[sectorSymbol].dir = -1;
      }

    },
    async getSectors(skipReload = false) {


      if (!skipReload) this.isLoading = true;
      const sectors = await DailyBriefService.sectorsPerformance();
      this.sectors = sectors.sectorsList;
      this.priceChanges = sectors.priceChanges;
      if (!skipReload) this.isLoading = false;
    },
    async loadHoldings(symbol) {
      this.holdings[symbol] = await DailyBriefService.getSectorsHoldings(symbol);
    },
    async showHoldings(symbol) {

      this.leaveStream();
      this.xAxisScrolled = false;
      if (symbol === this.showHoldingsForSymbol) {
        const ref = this.$refs[`${symbol}-holdings`];
        ref[0].style.height = `0px`
        setTimeout(() => {
          this.showHoldingsForSymbol = null;
        }, 10);
        return;
      }

      if (!this.holdings[symbol]) {

        if (this.showHoldingsForSymbol) {
          this.$refs[`${this.showHoldingsForSymbol}-holdings`][0].style.height = `0px`
        }

        this.currentlyLoading = symbol;
        this.holdingsLoading[symbol] = true;
        this.holdings[symbol] = await DailyBriefService.getSectorsHoldings(symbol);
        this.holdingsLoading[symbol] = false;
        this.showHoldingsForSymbol = symbol;
        this.currentlyLoading = null;

        this.updateStream(this.holdings[symbol].map(holding => holding.symbol), `sector-${symbol}`);

        if (!this.sortHoldings[symbol]) {

          this.sortHoldings[symbol] = {
            value: 'changeP',
            dir: -1
          }
        }


        this.$nextTick(() => {

          setTimeout(() => {
            const ref = this.$refs[`${symbol}-holdings`];
            ref[0].style.height = `${ref[0].scrollHeight}px`
          }, 300);
        });


      } else {
        this.showHoldingsForSymbol = symbol;
        this.updateStream(this.holdings[symbol].map(holding => holding.symbol), `sector-${symbol}`);

        this.$nextTick(() => {
          setTimeout(() => {
            const ref = this.$refs[`${symbol}-holdings`];
            ref[0].style.height = `${ref[0].scrollHeight}px`
          }, 300);
        })
      }
    }
  },
  beforeRouteLeave() {

    clearInterval(this.refreshInterval);
  },
  beforeUnmount() {
    clearInterval(this.refreshInterval);
  },
  async mounted() {
    await this.getSectors();

    if (!this.refreshInterval) {
      this.refreshInterval = setInterval(async () => {

        if (this.nyTime.isMarketOpen) {
          await this.getSectors(true);

          for (let k in this.holdings) {

            await this.getSectorsHoldings(k);
          }
        }
      }, 60000);
    }
  }
}
</script>

<style scoped lang="scss">

@import "../assets/style/vars";

main {

  h3 {
    margin: 0;
    padding: 5px $minSpacing;
  }
}

.holdings {
  //overflow-y: hidden;
  height: 0;
  transition: $toggleTransition;
  white-space: nowrap;

}

#barchartWrapper {
  margin: 0 0 -5rem;
  @media (max-width: 660px) {
    overflow-x: scroll;
  }
}

.sector {
  position: absolute;
  bottom: 0;
  height: 90%;
  left: 5%;
  width: 90%;
  background: var(--bgColor);
  z-index: 9999;
  border-radius: 12px;
  overflow: auto;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid var(--notficationBorderColor);

  @media(min-width: 1460px) {
    width: 70%;
    left: 15%;
  }

  @media(max-width: 660px) {
    height: 95%;
    width: 98%;
    left: 1%;
  }

  .sectorRow {
    padding: 5px 0;
    display: flex;
    align-items: center;

    &:hover {
      background: var(--tableHoverColor);
    }

    .holdingsToolbar[dir="rtl"] {
      margin: 0 auto 0 0;
    }

    .holdingsToolbar {
      margin: 0 0 0 auto;
    }
  }

  a {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}


.symbolName {
  font-weight: 600;
  font-size: 18px;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  gap: 10px;
}

.companyName {
  padding: 0 1rem;
  unicode-bidi: plaintext;
  @media(max-width: 660px) {
    padding: .5rem 1rem 0;
  }
}

.companyName[dir="rtl"] {
  text-align: right;
}


.sectorSymbol {
  justify-content: space-between;
  margin: 0 1rem !important;
  border-bottom: 1px solid var(--notficationBorderColor);

  @media(max-width: 600px) {
    gap: 5px;
    flex-direction: column;
    justify-content: flex-start !important;
    align-items: flex-start !important;
  }

  .symbolName {
    font-size: 16px;
    @media(max-width: 600px) {
      padding: .5rem 0 0 0;
    }
  }

  .companyName {
    @media(max-width: 600px) {
      padding: 0;
      font-size: 14px;
    }
  }
}

.sectorHeader {
  position: sticky;
  top: 0;
  background: var(--bgColor);
  border-bottom: 1px solid var(--notficationBorderColor);
  z-index: 1;
}

.symbolInfo {
  display: flex;
  align-items: center;
  gap: 20px;

  @media(max-width: 660px) {
    gap: 0;
  }

  .holdingStat {
    width: 80px;
    unicode-bidi: plaintext;

    &.filter {
      user-select: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 3px;
      font-size: 13px;
      font-weight: 600;
      height: 30px;
    }

    label {
      opacity: .6;
      font-weight: 500;
      font-size: 13px;
      display: none;
      @media(max-width: 600px) {
        display: block;
      }
    }
  }

  .holdingStat[dir="rtl"] {
    text-align: right;
    direction: rtl;
  }
}

.toggleHoldings {
  cursor: pointer;
  padding: 10px;
  font-size: 20px;
  border-radius: 5px;
  margin: 0 10px;
  //
  //@media (max-width: 600px) {
  //  display: none;
  //}

  @media(min-width: 600px) {
    display: block;
  }

  &:hover {
    background: var(--highlightColorOpacity);
    color: var(--highlightColor)
  }
}

.toggleHoldings.mobile {
  @media (max-width: 600px) {
    display: block;
  }
  @media(min-width: 600px) {
    display: none;
  }

  cursor: pointer;
  position: absolute;
  left: 0;
  top: 25%;
  transform: translateY(-50%);
}

.holdingsToolbar {
  margin: 0 1.5rem 0 2rem;
}

.sectorTools {
  display: flex;
  align-items: center;
  gap: 15px;
  //margin: 0 0 0 auto;
}

.sectorTools[dir="rtl"] {
  //margin: 0 auto 0 0;
}

.sectorOpen {
  background: var(--bgColor);
  backdrop-filter: blur(20px);
  padding: .35rem 1rem;
  border-bottom: 1px solid #eee;
  box-shadow: 0 5px 5px 0 rgba(15, 15, 15, .05);
  position: sticky;
  left: 0;
  z-index: 2;
  font-size: 22px;

  @media(max-width: 600px) {
    //flex-direction: column;
    padding: 15px 0;
    align-items: flex-start !important;
  }
}

#priceChangeTable {
  //padding: $minSpacing;
}


  tr {
    th:not(:first-of-type),
    td:not(:first-of-type){
      text-align: center;
    }

    td:first-of-type,
    th:first-of-type{
      width: 50px;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  thead {
    position: sticky;
    top: 57px;
    background: var(--bgColor);
    z-index: 2;
    @media(max-width: 660px) {
      top: 40px;
    }
    th{
      text-align: center;
      border-bottom: 2px solid var(--notficationBorderColor);
      padding: 10px 15px;
      div {
        cursor: pointer;
        user-select: none;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
      }
    }
  }

  tbody{
    tr{
      &:hover {
        background: var(--tableHoverColorOpacity);
      }
    }
    td{

      border-bottom: 1px solid var(--notficationBorderColor);
    }
  }

  .companyHoldingName {

    @media(max-width: 660px) {
      max-width: 130px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }
  }

.stickySymbol {
  background: var(--bgColor);
  position: sticky;
  left: 0;
  border-right: 1px solid var(--tableBorderColor);
  z-index: 1;
  &[dir="rtl"] {
    border-right: unset;
    border-left: 1px solid var(--tableBorderColor);
    left: unset;
    right: 0;
  }
}

.xAxisScrolled {
  box-shadow: 3px 0 2px 0 rgba(0, 0, 0, 0.05);
  border-right: 1px solid var(--notficationBorderColor);
  &[dir="rtl"] {
    border-right: unset;
    border-left: 1px solid var(--notficationBorderColor);
    box-shadow: -3px 0 2px 0 rgba(0, 0, 0, 0.05);
  }
}


th, td {
  &[dir="rtl"] {
    unicode-bidi: plaintext;
  }
  padding: 10px;
}


</style>
